import React, { useEffect , useState} from 'react';

import styles from './RuleTree.module.less';
import { SNLCategory, SNLLibrary, SNLRule } from '../../models/snl/SNLLibrary';
import { NodeRendererProps, Tree } from 'react-arborist';
import { useResizeDetector } from 'react-resize-detector';
import { BarsOutlined, FolderOutlined } from '@ant-design/icons';
import clsx from 'clsx';

interface RuleTreeProps {
  snlLibrary: SNLLibrary | null;
  activeRuleNumbers: string[];
}

type NodeProp = {
  id: string;
  number: string;
  title: string;
  children?: NodeProp[];
};

function TreeNode({ node, style }: NodeRendererProps<NodeProp>) {
  return (
    <div className={clsx(styles.node, node.state)} style={style} onClick={() => {
      if (node.isLeaf) {
        const target = document.getElementById(`content-${node.data.number}`);
        if (target) {
          target.scrollIntoView({block: 'start'});
        }
      } else {
        node.toggle();
      }
    }}>
      {
        node.isLeaf ?
          (
            <>
              <BarsOutlined />
              <span>{node.data.title}</span>
            </>
          ) :
          (
            <>
              <FolderOutlined />
              <span>{node.data.title}</span>
            </>
          )
      }
    </div>
  );
}

function RuleTree(props: RuleTreeProps) {
  const {snlLibrary, activeRuleNumbers} = props;
  const [active, setActive] = useState<NodeProp | null>(null);
  useEffect(() => {
    const target = document.getElementById(`toc-${activeRuleNumbers}`);
    // if (target) {
    //   target.scrollIntoView({block: 'start'});
    // }
  }, [activeRuleNumbers]);

  const { width, height, ref } = useResizeDetector({
    // refreshMode: 'throttle',
    // refreshRate: 500
  });

  const treeData = React.useMemo(() => {
    function _extractArboristTree(node: SNLCategory | SNLRule): NodeProp {
      if (node instanceof SNLRule) {
        return {
          id: `rule-${node.id}`,
          number: node.number,
          title: `${node.number} (${node.tags.map(t => t.name).join(',')})`,
        };
      } else {
        return {
          id: `category-${node.id}`,
          number: node.number,
          title: `${node.number} ${node.name}`,
          children: (node.children.length !== 0 ? node.children : node.rules).map(_extractArboristTree),
        };
      }
    }
    if (snlLibrary) {
      return [_extractArboristTree(snlLibrary.rootCategory)];
    } else {
      return [];
    }
  }, [snlLibrary]);

  // const defaultOpenState = React.useMemo(() => {
  //   const openState: {[key: string]: boolean} = {};
  //   function _trace(node: NodeProp) {
  //     if (node.children) {
  //       openState[node.id] = true;
  //       node.children.forEach((child) => {
  //         _trace(child);
  //       });
  //     }
  //   }
  //
  //   for (const t of treeData) {
  //     _trace(t);
  //   }
  //   return openState;
  // }, [treeData]);

  return (
    <div ref={ref} className={styles.root + ' ' + '23333'}>
      <Tree<NodeProp>
        data={treeData}
        openByDefault={true}
        selection={active?.id}
        width={width}
        height={height}
        indent={24}
        rowHeight={30}
        overscanCount={1}
        onActivate={(node) => setActive(node.data)}
        // initialOpenState={defaultOpenState}
      >
        {TreeNode}
      </Tree>
    </div>
  )
}

export default RuleTree;
