import React, { ChangeEventHandler, MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { Avatar, Button, Empty, Input, List, notification, Pagination, PaginationProps, Popconfirm, Space, Tag } from 'antd';

import styles from './ProjectList.module.less';
import { Link } from 'react-router-dom';
import ProjectListService from './ProjectList.service';
import { useAppSelector } from '../../app/hooks';
import { selectUser } from '../auth/authSlice';
import { Helmet } from 'react-helmet';
import Footer from '../../components/Footer';
import { Project } from '../../interfaces';

interface PaginationStatus {
  currentPage: number;
  pageSize: number;
}

interface PaginationResult {
  total: number;
  total_pages: number;
  first_page: number;
  last_page: number;
  page: number;
  next_page: number;
}

interface ProjectListProps {
  recycleBin?: boolean;
}

function ProjectList(props: ProjectListProps) {
  const { recycleBin } = props;

  const user = useAppSelector(selectUser);
  // 新建项目名称
  const [newProjectName, setNewProjectName] = useState('');
  // 分页配置状态
  const [pageStatus, setPageStatus] = useState<PaginationStatus>({
    currentPage: 1,
    pageSize: 10
  });
  // 当前页面项目列表
  const [projectList, setProjectList] = useState<Project[]>([]);
  // 分页结果
  const [pagination, setPagination] = useState<PaginationResult>({
    first_page: 0,
    last_page: 0,
    next_page: 0,
    page: 0,
    total: 0,
    total_pages: 0
  });

  const [realDelOpen, setRealDelOpen] = useState(false);
  const [realDelConfirmLoading, setRealDelConfirmLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');   // 新增搜索状态

  const fetchProjectData = useCallback(() => {
    ProjectListService
      .getProjectList(pageStatus.currentPage, pageStatus.pageSize, recycleBin ? 'recycle' : 'normal', searchTerm || undefined )
      .then(res => {
        const pageParams = JSON.parse(res.headers['x-pagination'] || '{}');
        setProjectList(res.data);
        setPagination(pageParams);
      });
  }, [pageStatus, recycleBin, searchTerm]);

  useEffect(() => {
    fetchProjectData();
  }, [pageStatus, recycleBin, searchTerm]);

  const onChangePage: PaginationProps['onChange'] = page => {
    setPageStatus({
      ...pageStatus,
      currentPage: page
    })
  };

  const onNewNameChange: ChangeEventHandler<HTMLInputElement> = event => {
    setNewProjectName(event.target.value);
  };

  const onCreateProject: MouseEventHandler = () => {
    if (newProjectName === '') {
      return;
    }
    ProjectListService
      .createProject(newProjectName)
      .then(res => {
        notification['success']({
          message: '新建项目成功',
          description:
            `项目 “${name}” 已成功创建。`,
        });
        setNewProjectName('');
        fetchProjectData();
      })
      .catch(res => {
        notification['error']({
          message: '新建项目失败',
          description:
            `新建项目 “${name}” 失败，请检查网络。`,
        });
      });
  };

  const onDeleteProject = async (projectId: number, realDelete = false) => {
    return ProjectListService
      .deleteProject(projectId, realDelete)
      .then(res => {
        notification['success']({
          message: '删除成功',
          description:
            `项目 #${projectId} 已删除成功。`,
        });
        fetchProjectData();
      })
      .catch(res => {
        notification['error']({
          message: '删除失败',
          description:
            `项目 #${projectId} 删除失败，请检查网络。`,
        });
      });
  };

  const showPopconfirm = () => {
    setRealDelOpen(true);
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setRealDelOpen(false);
  };

  const handleOk = async (projectId: number) => {
    // todo 组件库内部有bug，异步等待暂时无法正常显示loading状态
    // setRealDelConfirmLoading(true);

    onDeleteProject(projectId, true);
    // .then(() => {
    //   // setRealDelOpen(false);
    //   // setRealDelConfirmLoading(false);
    // });

  };
  const onSearchChange: ChangeEventHandler<HTMLInputElement> = event => {
    setSearchTerm(event.target.value);
  };
  return (
    <div className={styles.root}>
      <Helmet>
        <title>图智项目列表</title>
      </Helmet>
      <div
        className={styles.listWrapper}
        style={{
          backgroundColor: recycleBin ? '#efe' : '#fff',
        }}
      >
        {
          !recycleBin ?
            <Link to='/recyclebin'>
              <Button type="default" style={{float: 'right'}}>
                回收站
              </Button>
            </Link> :
            <Link to='/projects' >
              <Button type="default" style={{float: 'left'}}>
                项目列表
              </Button>
            </Link>
        }
        <h1 style={{clear: 'both'}}><img src="/tuzhi-logo.svg" alt="图智"/> “图智” TUZHI.AI {recycleBin ? '项目回收站' : '项目列表'}</h1>
        {
          !recycleBin &&
          <div className={styles.searchContainer}>
            <Space direction="vertical" className={styles.projectNameInputGroup}>
              <Space.Compact block>
                <Input
                  placeholder="创建项目名称"
                  value={newProjectName}
                  onChange={onNewNameChange}
                />
                <Button
                  type="primary"
                  onClick={onCreateProject}
                >
                  创建项目
                </Button>
              </Space.Compact>
              <Space.Compact block>
                <Input
                  placeholder="搜索项目名称或id"
                  value={searchTerm}
                  onChange={onSearchChange}
                />
              </Space.Compact>
            </Space>
          </div>
        }
        {/*todo 加载中界面优化，如使用骨架屏*/}
        <List
          // itemLayout="vertical"
          size="large"
          locale={{emptyText: <Empty description="暂无项目"/>}}
          dataSource={projectList}
          renderItem={item => {
            const source = item.properties.find(p => p.key === '数据源类型')?.value;
            let stateJSX = null;
            switch (item.state) {
              case 'PREPARING':
                stateJSX = <Tag color="orange">文件准备中</Tag>;
                break;
              case 'PENDING':
                stateJSX = <Tag color="orange">项目排队中</Tag>;
                break;
              case 'PROGRESS':
                stateJSX = <Tag color="orange">项目运行中</Tag>;
                break;
              case 'SUCCESS':
                stateJSX = <Tag color="green">项目成功结束</Tag>;
                break;
              case 'FAILURE':
                stateJSX = <Tag color="red">项目失败结束</Tag>;
                break;
              case 'REVOKE':
                stateJSX = <Tag color="red">项目撤销</Tag>;
                break;
              case 'TIMEOUT':
                stateJSX = <Tag color="red">项目超时</Tag>;
                break;
            }
            return (
              <List.Item
                key={item.projectId}
                actions={[
                  <Button key="item-rename">
                    <Link to={`/project/${user.username}/${item.projectId}/${item.name}`}>
                      进入项目
                    </Link>
                  </Button>,
                  <Button key="item-rename" onClick={() => alert('todo')}>重命名</Button>,
                  !recycleBin && <Popconfirm
                    key="item-delete" placement="top" title="确认删除"
                    okText="删除" cancelText="取消"
                    onConfirm={() => onDeleteProject(item.projectId, false)}
                  >
                    <Button>删除</Button>
                  </Popconfirm>,
                  recycleBin && <Popconfirm
                    key="item-restore" placement="top" title="确认还原"
                    okText="还原项目" cancelText="取消"
                    onConfirm={() => onDeleteProject(item.projectId)}
                  >
                    <Button>还原</Button>
                  </Popconfirm>,
                  recycleBin && <Popconfirm
                    key="item-real-delete" placement="top" title="确认粉碎"
                    okText="彻底粉碎项目" cancelText="取消"
                    open={realDelOpen}
                    onConfirm={() => handleOk(item.projectId)}
                    okButtonProps={{ loading: realDelConfirmLoading }}
                    onCancel={() => setRealDelOpen(false)}
                  >
                    <Button
                      onClick={() => setRealDelOpen(true)}
                    >粉碎</Button>
                  </Popconfirm>,
                ].filter(Boolean)}
              >
                <div>
                  <Avatar size="large" className={styles.avatar}>{item.name.slice(0, 2)}</Avatar>
                </div>
                <div className={styles.listItemBody}>
                  <div>
                    <Link to={`/project/${user.username}/${item.projectId}/${item.name}`}>
                      {item.name} #{item.projectId}
                    </Link>
                  </div>
                  <div>
                    {new Date(item.createTime).toLocaleString()}{' '}
                    {stateJSX}
                    {item.state === 'PENDING' && <Tag color="yellow">排队位置：{item.position}</Tag> }
                    {source ? <Tag color="green">全自动项目 - {source}</Tag> : null}
                  </div>
                </div>
              </List.Item>
            );
          }}
        />
        <Pagination
          className={styles.pagination}
          defaultCurrent={1} total={pagination.total}
          current={pagination.page}
          pageSize={pageStatus.pageSize}
          onChange={onChangePage}
          showTotal={total => `共 ${total} 个项目`}
        />
      </div>
      <Footer/>
    </div>
  );
}

export default ProjectList;
