import React, { useContext, useEffect, useState } from 'react';
import { Button, Dropdown, message, Popconfirm, Tag, Tree } from 'antd';
import { FieldDataNode } from 'rc-tree/lib/interface';
import styles from './AssetTree.module.less';
import UploadAssetModal from './UploadAssetModal';
import { Asset } from '../../../interfaces';
import TuzhiVisService from '../TuzhiVis.service';
import { useAppSelector } from '../../../app/hooks';
import { selectAssets } from '../tuzhiVisSlice';
import * as FlexLayout from 'flexlayout-react';
import { LayoutContext } from '../TuzhiVis';
import { CheckCircleTwoTone, ClockCircleTwoTone, PauseCircleTwoTone } from '@ant-design/icons';
import { saveAs } from 'file-saver';

interface AssetTreeProps {
  projectId: number;
}

type AssetTreeDataNode = FieldDataNode<{
  key: string;
  title: string;
  asset: Asset | null;
}>;

const onRecognizeAll = (projectId: number) => {
  TuzhiVisService.recognizeProject(projectId)
    .then(res => {
      message.success(`开始一键识别导出,请留意总识别进度`);
    })
    .catch(err => {
      message.error(`一键识别导出失败: ${err.response.data.message}`);
    });
};

const onRerun = (projectId: number) => {
  TuzhiVisService.rerunProject(projectId)
    .then(res => {
      message.success(`开始重新识别，请打开新项目浏览`);
      window.location.replace(`/projects`);
    });
};


function AssetTree(props: AssetTreeProps) {
  const { projectId } = props;

  const layoutContext = useContext(LayoutContext);

  const assets = useAppSelector(selectAssets);

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const [assetTree, setAssetTree] = useState<AssetTreeDataNode[]>([]);
  const [expandKeys, setExpandKeys] = useState<React.Key[]>([]);

  useEffect(() => {
    // 通过线性目录构造目录树
    const treeRoot: AssetTreeDataNode = {
      title: '项目根目录',
      key: 'root',
      asset: null,
      children: [],
    };
    const expandKeys: string[] = ['root'];

    // 先处理原始资源，构造基本文件目录树
    const treeNodes: Map<number, AssetTreeDataNode> = new Map();
    Object.values(assets).filter(file => file.granularity === 'ORIGIN').forEach(file => {
      const path = file.storeDir.concat([file.name + file.extension]);
      let currentNode = treeRoot;
      let currentKey = 'root';
      for (let i = 0; i < path.length; i++) {
        currentKey += '/' + path[i];
        if (!currentNode.children) {
          currentNode.children = [];
        }
        let node = currentNode.children.find(n => n.key === currentKey);
        let key, nodeAsset;
        if (i === path.length - 1) {
          key = file.assetId.toString(); // 文件的key可以直接使用资源ID
          nodeAsset = file;
        } else {
          key = currentKey; // 目录的key只能用名称直接构造
          nodeAsset = null;
        }
        if (!node) {
          node = {
            title: path[i],
            key: key,
            asset: nodeAsset,
            children: [],
          };
          currentNode.children.push(node);
          treeNodes.set(file.assetId, node);
          expandKeys.push(key);
        }
        // @ts-ignore todo
        currentNode = node;
      }
    });

    const insertNode = (assetId: number) => {
      if (treeNodes.has(assetId))
        return;
      const asset = assets[assetId.toString()];
      if (!asset)
        throw new Error(`assetId不存在: ${assetId}`);
      let parentTreeNode: AssetTreeDataNode;
      if (asset.ancestorIds.length > 0) {
        const parentAssetId = asset.ancestorIds[asset.ancestorIds.length - 1];
        // 递归插入节点
        if (!treeNodes.has(parentAssetId))
          insertNode(parentAssetId);
        try {
          parentTreeNode = treeNodes.get(parentAssetId) as AssetTreeDataNode;
        } catch (e) {
          throw new Error('父节点资源尚未构造进资源树中');
        }
      } else {
        parentTreeNode = treeRoot;
      }
      if (!parentTreeNode.children)
        parentTreeNode.children = [];
      if (!parentTreeNode.children.find(n => n?.asset?.assetId === assetId)) {
        const node = {
          title: asset.name + asset.extension,
          key: asset.assetId.toString(),
          asset: asset,
          children: [],
        };

        parentTreeNode.children.push(node);
        treeNodes.set(asset.assetId, node);
      }
    };

    // 再处理非原始资源的衍生资源
    Object.values(assets).forEach((a, id) => {
      if (!['DIRECTORY', 'ORIGIN'].includes(a.granularity))
        return;
      insertNode(a.assetId);
    });
    // 再处理内部资源、变种资源平行挂载父资源下
    Object.values(assets).forEach((a, id) => {
      if (['DIRECTORY', 'ORIGIN'].includes(a.granularity))
        return;
      insertNode(a.assetId);
    });

    setAssetTree(() => [treeRoot]);
    setExpandKeys(() => expandKeys);

  }, [assets]);

  const onExpand = (expandedKeysValue: React.Key[]) => {
    setExpandKeys(expandedKeysValue);
  };

  const onPreview = (assetId: number) => {
    // 新建tab页或跳转tab页，打开当前资源
    const tabId = `asset-${assetId}`;
    if (layoutContext.model.getNodeById(tabId)) {
      layoutContext.model.doAction(
        FlexLayout.Actions.selectTab(tabId),
      );
    } else {
      layoutContext.model.doAction(FlexLayout.Actions.addNode(
        {
          id: tabId, type: 'tab',
          component: 'asset-viewer',
          name: `[资源]${assets[assetId.toString()].name}`,
          config: {assetId: assetId},
        },
        'viewer-pane', FlexLayout.DockLocation.CENTER, -1, true,
      ));
    }
  };

  const onRecognize = (assetId: number) => {
    // 新建tab页或跳转tab页，打开当前资源
    TuzhiVisService.recognizeOriginAsset(assetId)
      .then(res => {
        message.info(`开始识别当前资源, ${assetId}`);
      });
  };

  return (
    <div className={styles.panelWrapper}>
      <div className={styles.tools}>
        <Button.Group size="small">
          {/*<Button>上传文件</Button>*/}
          <Button
            onClick={() => setIsUploadModalOpen(!isUploadModalOpen)}
          >
            上传压缩包
          </Button>
          {/*<Button>显示执行流程DAG图</Button>*/}
          <Popconfirm
            title="是否确认开始一键识别与导出?"
            onConfirm={() => onRecognizeAll(projectId)}
            okText="是"
            cancelText="否"
          >
            <Dropdown.Button
              size="small"
              menu={{
                items: [
                  {
                    key: 'revoke-pending',
                    label: '停止识别',
                  },
                  {
                    key: 'split-only',
                    label: '仅CAD转PDF',
                  },
                  {
                    key: 'download-origin',
                    label: '下载原图纸包',
                  },
                  // {
                  //   key: '3',
                  //   label: '下载CAD转PDF图纸包',
                  // },
                  // {
                  //   key: 'rerun',
                  //   label: '重新推导',
                  // },
                ], onClick: e => {
                  switch (e.key) {
                    case 'revoke-pending':
                      TuzhiVisService.revokeProjectPendingTasks(projectId)
                        .then(res => {
                          message.success('成功停止识别项目');
                        });
                      break;
                    case 'split-only':
                      message.info('开始CAD转PDF');
                      TuzhiVisService.splitProjectFiles(projectId)
                        .then(res => {
                          message.success('成功提交CAD转PDF任务，请耐心等待任务完成');
                        });
                      break;
                    case 'download-origin':
                      message.info('开始下载原始图纸包，请等待');
                      TuzhiVisService.downloadOriginZip(projectId)
                        .then(res => {
                          saveAs(res.data, `图纸包-${projectId}.zip`);
                          message.success('原始图纸包下载成功');
                        });
                      break;
                  }
                },
              }}
            >
              一键识别导出
            </Dropdown.Button>
          </Popconfirm>
          {/*<Popconfirm*/}
          {/*  title="是否确认开始重新推导?将使用人工修正的数据重新识别并推导。"*/}
          {/*  onConfirm={() => onRerun(projectId)}*/}
          {/*  okText="是"*/}
          {/*  cancelText="否"*/}
          {/*>*/}
          {/*</Popconfirm>*/}
        </Button.Group>
        <UploadAssetModal projectId={projectId} open={isUploadModalOpen} setOpen={setIsUploadModalOpen} />
      </div>
      <div>
        {/*当前进度、筛选文件树，空间树可以两个根节点，一个是空间层，一个是对应原始图纸最小识别单元*/}
      </div>
      <div className={styles.treeWrapper}>
        {/*<div>搜索文件、清空按钮</div>*/}
        <Tree.DirectoryTree
          showLine
          defaultExpandAll
          onExpand={onExpand}
          expandedKeys={expandKeys}
          autoExpandParent={false}
          treeData={assetTree}
          titleRender={(data) => {
            if (typeof data.title === 'string') {
              const assetId = data.asset?.assetId.toString();
              return (
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: 'preview',
                        disabled: data?.asset?.granularity === 'DIRECTORY' || !data.asset,
                        label: (
                          <div
                            onClick={e => {
                              onPreview(data.asset!.assetId);
                            }}
                          >
                            预览资源
                          </div>
                        ),
                      },
                      {
                        key: 'rec',
                        disabled: data?.asset?.granularity !== 'ORIGIN',
                        label: (
                          <div
                            onClick={(e) => {
                              onRecognize(data.asset!.assetId);
                            }}
                          >
                            {/*<Popconfirm*/}
                            {/*  title="是否确认开始识别?"*/}
                            {/*  onConfirm={() => onRecognize(data.asset!.assetId)}*/}
                            {/*  okText="是"*/}
                            {/*  cancelText="否"*/}
                            {/*  placement="right"*/}
                            {/*>*/}
                            {/*  识别资源*/}
                            {/*</Popconfirm>*/}
                            识别资源
                          </div>
                        ),
                      },
                      {
                        key: 'download',
                        label: (
                          <div
                            onClick={e => {
                              onPreview(data.asset!.assetId);
                            }}
                          >
                            下载资源
                          </div>
                        ),
                      },
                    ],
                    style: {minWidth: 100, textAlign: 'center'},
                  }} trigger={['contextMenu']}
                >
                  <div style={{display: 'flex', marginRight: 10}}>
                    <div
                      style={{whiteSpace: 'nowrap', flex: '1 0'}}>{assetId ? <Tag>{assetId}</Tag> : ''} {({
                        'UNTREATED': <PauseCircleTwoTone title="UNTREATED" />,
                        'FINISH_SPLIT': <CheckCircleTwoTone title="FINISH_SPLIT" />,
                        'EXTRACTING': <ClockCircleTwoTone title="EXTRACTING" />,
                        'SPLITTING': <ClockCircleTwoTone title="SPLITTING" />,
                        'FINISH_REC': <CheckCircleTwoTone title="FINISH_REC" />,
                    })[data?.asset?.state ?? 'UNTREATED']} {data.title}</div>
                  </div>
                </Dropdown>
              );
            }
          }}
        />
      </div>
    </div>
  );
}

export default React.memo(AssetTree);