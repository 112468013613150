import React, { useEffect, useRef, useState } from 'react';
import * as PDFjs from 'pdfjs-dist';
import { PDFDocumentProxy, RenderTask } from 'pdfjs-dist';
import { RenderParameters } from 'pdfjs-dist/types/src/display/api';
import useHooks from '../../../../utils/useHooks';
import UnionViewerService from '../UnionViewer.service';
import { AssetSpace } from '../../../../interfaces';
import { Point } from 'geojson';
import _ from 'lodash';
import { useDebouncedEffect } from '../../../../hooks/useDebouncedEffect';

// @ts-ignore
PDFjs.disableWebGL = false;
// PDFjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.6.172/pdf.worker.js';
PDFjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.js';

interface PDFLayerProps {
  spaceId: number;
  assetSpace: AssetSpace;
  offset: Point;
  globalOffset: Point;
  matrix: number[];
  scale: number; // 缩放比例
  width: number;
  height: number;
  setMatrix: React.Dispatch<React.SetStateAction<number[]>>;
  onRendered?: () => void;
}

function PDFLayer(props: PDFLayerProps) {
  const {spaceId, assetSpace, offset, globalOffset, matrix, scale, width, height, setMatrix, onRendered} = props;

  const prevMatrix = useHooks(matrix);

  // pdf解析可视化相关
  const [pdfDoc, setPdfDoc] = useState<PDFDocumentProxy | null>(null);
  const [renderTask, setRenderTask] = useState<RenderTask | null>(null);

  const [idle, setIdle] = useState(true);

  // pdf ref
  const pdfCanvasRef = useRef<HTMLCanvasElement>(null);
  const thumbCanvasRef = useRef<HTMLCanvasElement>(null);

  const renderDebounce = useRef(_.debounce(() => {
    if (pdfDoc) {
      console.log('debounce重新渲染');
      renderPDFPage(pdfDoc, 1);
    }
  }, 1000));

  const renderPDFPage = (pdf: PDFDocumentProxy, pageNumber: number) => {
    pdf.getOptionalContentConfig().then(oc => {
      // console.log(oc);
      // console.log(oc.getGroups());
    });

    pdf.getPage(pageNumber).then(function (page) {
      const canvas = pdfCanvasRef.current;
      //
      // page.getOperatorList().then(op => {
      //   console.log(op)
      // });

      if (canvas) {
        const context = canvas.getContext('2d');
        canvas.width = width;
        canvas.height = height;

        // const realScale = assetSpace.scale >= 4 && assetSpace.scale <= 12 ? assetSpace.scale : 6.350000233;
        // const realScale = assetSpace.scale >= 4 && assetSpace.scale <= 12 ? assetSpace.scale : 6.336;
        const realScale = assetSpace.scale;
        // 先变换回真实坐标，平移，再变换回屏幕坐标
        const viewport = page.getViewport({
          scale: matrix[0] * 400 / 72 * realScale,
          offsetX: matrix[4] - (offset.coordinates[0] + globalOffset.coordinates[0]) * matrix[0],
          offsetY: matrix[5] - (offset.coordinates[1] + globalOffset.coordinates[1]) * matrix[3],
        });

        // const defaultViewport = page.getViewport({scale: 1});

        const renderContext = {
          canvasContext: context,
          background: 'rgba(0,0,0,0)',
          transform: [1, 0, 0, 1, 0, -viewport.height],
          viewport
        };
        if (renderTask) {
          renderTask.cancel();
        }
        const _renderTask = page.render(renderContext as RenderParameters);
        setRenderTask(_renderTask);
        _renderTask.promise
          .then(function () {
            // todo 去除白色像素，用于处理纯图片打印的pdf
            if (context) {
              const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
              const data = imageData.data;
              for (let i = 0; i < data.length; i += 4) {
                if (data[i] === 255 && data[i + 1] === 255 && data[i + 2] === 255) {
                  data[i] = 255; // red
                  data[i + 3] = 0;
                }
              }
              context.putImageData(imageData, 0, 0);
            }

            // 渲染完毕则置为idle
            setIdle(true);
          })
          .catch(reason => {
            // 如取消渲染时RenderingCancelledException等可在此处捕获
          });
      }
    })
  };

  useEffect(() => {
    if (pdfCanvasRef.current) {
      console.log('加载PDF资源文件', assetSpace.asset.storeDir);
      UnionViewerService.getOriginFileArrayBuffer(assetSpace.asset.assetId)
        .then(res => {
          const loadingTask = PDFjs.getDocument(res.data);
          loadingTask.promise.then(function(pdf) {
            setPdfDoc(pdf);
            const pageNumber = 1; // todo 是否涉及多页pdf
            // 加载完成后，首先进行绘制区域定位，即放置在屏幕中央
            pdf.getPage(pageNumber).then((page) => {
              // 获取图层
              // pdf.getOptionalContentConfig().then(oc => {
              //   console.log('图层', oc.getGroups());
              // });
              // 不能使用原生的view
              // const [x1, y1, x2, y2]= page.view;
              // const scale = width / (x2 - x1) / ( 400 / 72 * assetSpace.scale);
              // const scale = width / (y2 - y1) / ( 400 / 72 * assetSpace.scale);

              // 先渲染一个缩略图，用于动画处理等情况
              const thumbCanvas = thumbCanvasRef.current;
              if (thumbCanvas) {
                const thumbContext = thumbCanvas.getContext('2d');
                // 需要使用视口计算，以避免PDF自带旋转的bug
                const thumbViewport = page.getViewport({
                  scale: 1,
                });
                thumbCanvas.width = thumbViewport.width;
                thumbCanvas.height = thumbViewport.height;
                const thumbRenderContext = {
                  canvasContext: thumbContext,
                  background: 'rgba(0,0,0,0)',
                  transform: [1, 0, 0, 1, 0, 0],
                  viewport: thumbViewport,
                };
                const _renderTask = page.render(thumbRenderContext as RenderParameters);
              }



              // 需要使用视口计算，以避免PDF自带旋转的bug
              const viewport = page.getViewport({
                scale: 1,
              });
              const viewportScale = width / viewport.width;
              const matrixScale = width / viewport.width / ( 400 / 72 * assetSpace.scale);

              console.log(page.view, matrixScale);
              // 首先暴力更新视口坐标
              setMatrix([matrixScale, 0, 0, -matrixScale, 0, (height + viewport.height * viewportScale) / 2]);
              // 初始化PDF完毕，调用通知父组件渲染完毕
              if (onRendered)
                onRendered();
              // setMatrix([scale, 0, 0, -scale, 0, height - (height - viewport.height) / 2]);
            });

            renderPDFPage(pdf, pageNumber);
          }, function (reason) {
            console.error(reason);
          });
        });
      // 销毁pdf对象
      return () => {
        pdfDoc?.destroy();
      };
    }
  }, [spaceId, pdfCanvasRef, assetSpace]);

  useEffect(() => {
    // if (prevMatrix?.[0] === matrix[0] && prevMatrix?.[3] === matrix[3]) {
    //   // 仅仅进行了平移变换，无须重新渲染，先平移
    //
    // }
    // 只要鼠标开始操作，就需要清空canvas，避免原始滞后
    setIdle(false);
    if (renderTask) {
      renderTask.cancel();
    }
    if (pdfCanvasRef.current) {
      const context = pdfCanvasRef.current.getContext('2d');
      if (context)
        context.clearRect(0, 0, width, height);
    }
  }, [spaceId, matrix, width, height]);

  useDebouncedEffect(() => {
    if (pdfDoc) {
      console.log('debounce渲染触发');
      renderPDFPage(pdfDoc, 1);
    }
  }, [spaceId, matrix, width, height],  200);

  return (
    <div
      style={{
        zIndex: 200,
        background: 'transparent',
        position: 'absolute'
      }}
    >
      <canvas
        ref={thumbCanvasRef}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          transformOrigin: 'left top',
          transform: `translate(0, -${matrix[0] * 400 / 72 * assetSpace.scale * 100}%) translate(${matrix[4] - (offset.coordinates[0] + globalOffset.coordinates[0]) * matrix[0]}px, ${matrix[5] - (offset.coordinates[1] + globalOffset.coordinates[1]) * matrix[3]}px) scale(${matrix[0] * 400 / 72 * assetSpace.scale + ''})`,
          imageRendering: 'pixelated',
          display: idle ? 'none' : '',
        }}
      />
      <canvas
        ref={pdfCanvasRef}
        style={{
          // filter: 'invert(1)'
          display: idle ? '' : 'none',
        }}
      />
    </div>
  );
}

export default PDFLayer;