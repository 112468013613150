import React from "react";
import { Row, Col, Button, message } from "antd";
import { useState, useEffect } from "react";
import styles from "./DiffFunction.module.less";
import ConsistencyViewerService from "../ConsistencyViewer.service";

interface DiffFunctionProps {
  projectId: number;
}

interface ResultJson {
  roomResults: Array<any>;
  memberResults: Array<any>;
}

function DiffFunction(props: DiffFunctionProps) {
  const { projectId } = props;
  const [roomFinished, setRoomFinished] = useState(false);
  const [memberFinished, setMemberFinished] = useState(false);
  useEffect(() => {
    ConsistencyViewerService.getConsistencyResult(projectId).then(
      (res: any) => {
        const jsonData: ResultJson = res.data;
        //如果roomResults为空，说明房间比对算法未执行
        if (jsonData.roomResults.length == 0) {
          setRoomFinished(false);
        } else {
          setRoomFinished(true);
        }
        //如果memberResults为空，说明构件比对算法未执行
        if (jsonData.memberResults.length == 0) {
          setMemberFinished(false);
        } else {
          setMemberFinished(true);
        }
      }
    );
  }, []); // Empty dependency array to run this effect only once
  return (
    <div className={styles.root}>
      <Row>
        <Col span={11} className={styles.singleCol}>
          <div>房间图元</div>
        </Col>
        <Col
          span={6}
          className={styles.singleCol}
          style={{ color: roomFinished ? "green" : "blue" }}
        >
          <div>{roomFinished ? "已完成" : "未完成"}</div>
        </Col>
        <Col span={7} className={styles.singleCol}>
          <Button
            type="primary"
            onClick={() => {
              message.info("开始执行房间比对算法");
              try {
                setRoomFinished(false);
                ConsistencyViewerService.executeRoomComparison(projectId).then(
                  (res: any) => {
                    const status = res.data["status"];
                    if (status == "pdf_missing") {
                      message.error("图纸Bcjson尚未提交");
                    } else if (status == "bim_missing") {
                      message.error("模型Bcjson尚未提交");
                    } else if (status == "success") {
                      setRoomFinished(true);
                      message.success("房间比对算法运行完成");
                    }
                  }
                );
              } catch (error) {
                // 处理错误的逻辑
                message.error("执行房间比对算法时发生错误");
                console.error(
                  "Error occurred while conducting algorithm:",
                  error
                );
              }
            }}
          >
            执行
          </Button>
        </Col>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Col span={11} className={styles.singleCol}>
          <div>构件图元</div>
        </Col>
        <Col
          span={6}
          className={styles.singleCol}
          style={{ color: memberFinished ? "green" : "blue" }}
        >
          <div>{memberFinished ? "已完成" : "未完成"}</div>
        </Col>
        <Col span={7} className={styles.singleCol}>
          <Button
            type="primary"
            onClick={() => {
              message.info("开始执行构件图元比对算法");
              try{
                setMemberFinished(false);
                ConsistencyViewerService.executeMemberComparison(projectId).then(
                    (res: any) => {
                      const status = res.data["status"];
                      if (status == "pdf_missing") {
                        message.error("图纸Bcjson尚未提交");
                      } else if (status == "bim_missing") {
                        message.error("模型Bcjson尚未提交");
                      } else if (status == "success") {
                        setMemberFinished(true);
                        message.success("构件图元比对算法运行完成");
                      }
                    }
                  );
              }
              catch(error){
                message.error("执行构件图元比对算法时发生错误");
                console.error("Error occurred while conducting algorithm:", error);
              }
            }}
          >
            执行
          </Button>
        </Col>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Col span={11} className={styles.singleCol}>
          <div>房间属性集</div>
        </Col>
        <Col span={6} className={styles.singleCol}>
          <div>开发中</div>
        </Col>
        <Col span={7} className={styles.singleCol}>
          <Button type="primary" disabled>
            执行
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default DiffFunction;
