import * as FlexLayout from 'flexlayout-react';

export const layout: FlexLayout.IJsonModel = {
  global: {
    splitterSize: 4,
    splitterExtra: 4,
  },
  borders: [],
  layout: {
    type: 'row',
    id: 'root',
    children: [
      {
        type: 'row',
        id: 'left-pane',
        weight: 1,
        children: [
          {
            type: 'tabset',
            id: 'space-pane',
            weight: 4,
            children: [
              {
                type: 'tab',
                id: 'space',
                name: process.env.REACT_APP_THEME === 'szzj' ? '图纸列表' : '空间树',
                component: 'space-tree',
              },
            ],
          },
        ],
      },
      {
        type: 'row',
        id: 'center-pane',
        weight: 4,
        children: [
          {
            type: 'tabset',
            id: 'viewer-pane',
            weight: 4,
            children: [
              // {
              //   type: 'tab',
              //   id: 'viewer-summary',
              //   name: '项目摘要',
              //   enableClose: false,
              //   component: 'project-summary',
              // },
              {
                type: 'tab',
                id: 'viewer-summary',
                name: 'AI审图',
                enableClose: false,
                component: 'empty',
              },
            ],
          },
          {
            type: 'tabset',
            id: 'check-pane',
            weight: 1,
            children: [
              {
                type: 'tab',
                id: 'check',
                name: '审查面板',
                component: 'check',
                enableFloat: true,
              },
            ],
          },
        ],
      },
    ],
  },
};
