import React, { useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Button,
  Checkbox,
  Form,
  Input,
  List,
  message,
  Popconfirm,
  Progress,
  Select,
  Space,
  Tabs,
  Tooltip,
  TreeSelect,
  Upload,
} from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import { CheckCircleFilled, CloseCircleFilled, UploadOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  addElements,
  addGeoms,
  selectChecks,
  selectCheckSpaceId,
  selectSNLs,
  selectSpaces,
  setCheckSpaceId,
} from '../tuzhiVisSlice';
import ResultTypeList from './ResultTypeList';
import styles from './ModelChecker.module.less';
import ModelCheckerService from './ModelChecker.service';
import CheckReportViewer from './CheckReportViewer';
import { CheckReport, CheckResult } from '../../../interfaces';
import { formatDate } from '../../../utils/dateFormatter';
import { saveAs } from 'file-saver';
import { FieldDataNode } from 'rc-tree/lib/interface';
import OnlineSNLForm from './OnlineSNLForm';
import boxImg from '../../../assets/icons/box-16.png';
import jsonImg from '../../../assets/icons/json-16.png';
import csvImg from '../../../assets/icons/csv-16.png';
import pdfImg from '../../../assets/icons/pdf-16.png';
import * as FlexLayout from 'flexlayout-react';
import { useResizeDetector } from 'react-resize-detector';
import TuzhiTree from '../../../components/tuzhi-tree/TuzhiTree';
import { buildSingleSNL } from '../../../utils/snlTemplate';
import { reportToCsv } from '../../../utils/reportToCsv';
import { RuleTreeDataNode } from '../../../utils/snlUtils';
import { SNLLibrary } from '../../../models/snl/SNLLibrary';
import {checkLayout as szzjCheckLayout} from '../../../themes/szzj/checkLayout';


const handlePresetCheck = (spaceId: number | null, checkName: string | null, presetName: string | null, presetTagStr: string, useFilter: boolean, relateSnlConfigFile: File | null, useRemoveDuplicate: boolean, noIfCheck: boolean,) => {
  if (!spaceId)
    throw new Error('未选择待审查的空间');
  if (!presetName)
    throw new Error('未选择预设配置');
  // 读取条文关联配置文件内容，转为字符串发送到后端
  if (relateSnlConfigFile != null) {
    const reader = new FileReader();
    reader.onload = (e) => {
      let relateSnlConfigStr = null;
      if (e.target != null && e.target.result != null) relateSnlConfigStr = e.target.result.toString();

      ModelCheckerService.submitPresetCheck(spaceId, checkName || presetName, presetName, presetTagStr, useFilter, relateSnlConfigStr, useRemoveDuplicate, noIfCheck)
        .then(res => {
          message.success('成功提交预设审查, 请关注审查进度列表');
        })
        .catch(res => {
          alert(res)
          message.error('提交预设审查失败');
        });
    };
    reader.readAsText(relateSnlConfigFile);
    return;
  }
  // 发送审查请求
  ModelCheckerService.submitPresetCheck(spaceId, checkName || presetName, presetName, presetTagStr, useFilter, null, useRemoveDuplicate, noIfCheck)
    .then(res => {
      message.success('成功提交预设审查, 请关注审查进度列表');
    })
    .catch(res => {alert(res)
      message.error('提交预设审查失败');
    });
};


const handleOnlineSnlCheck = (spaceId: number | null, checkName: string | null, use_filter: boolean, relateSnlFile: File | null, form: FormInstance, useRemoveDuplicate: boolean, noIfCheck: boolean) => {
  if (!spaceId) {
    throw new Error('未选择待审查的空间');
  }

  form.validateFields()
    .then((values) => {
      const formValues = form.getFieldsValue();
      const onlineSnlList = formValues.snlList;
      const snlFileList: File[] = [];
      const ruleList: string[][] = [];
      onlineSnlList.forEach((item: any, index: number) => {
        const { selectedRules, selectedTags, selectedSnlTags, snlFile, snlFileData } = item.componentData;
        let processedSnlFile: File | null = null;
        if (selectedSnlTags.length !== 0) {
          const data = rebuildSnlFileContent(selectedSnlTags, snlFileData, selectedRules);
          processedSnlFile = new File([data], snlFile.name);
        }else{
          processedSnlFile = snlFile;
        }
        // 添加处理后的 SNL 文件和规则到对应的列表中
        if (processedSnlFile) {
          snlFileList.push(processedSnlFile);
          ruleList.push(selectedRules);
        }
      });
      const snlFileNames = snlFileList.map(snlFile => snlFile.name.split('.')[0]).join(',');
      // 条文关联处理
      // 读取条文关联配置文件内容，转为字符串发送到后端
      if (relateSnlFile != null) {
        const reader = new FileReader();
        reader.onload = (e) => {
          let relateSnlStr = null;
          if (e.target != null && e.target.result != null) relateSnlStr = e.target.result.toString();

          ModelCheckerService.submitMultiCheck(spaceId, checkName || snlFileNames, snlFileList, ruleList, use_filter, relateSnlStr, useRemoveDuplicate, noIfCheck)
            .then(res => {
              message.success('成功提交审查, 请关注审查进度列表');
            })
            .catch(res => {
              console.error(res);
            });
        };
        reader.readAsText(relateSnlFile);
        return;
      }
      ModelCheckerService.submitMultiCheck(spaceId, checkName || snlFileNames, snlFileList, ruleList, use_filter, null, useRemoveDuplicate, noIfCheck)
        .then(res => {
          message.success('成功提交审查, 请关注审查进度列表');
        })
        .catch(res => {
          console.error(res);
        });
    })
    .catch(errorInfo => {
      console.error(errorInfo);
    });
};



const handleCheck = (spaceId: number | null, checkName: string | null, snlFile: File | null, rules: string[], use_filter: boolean, snlFileData: string, selectedSnlTags: string[], relateSnlFile: File | null, noIfCheck: boolean) => {
  if (!spaceId)
    throw new Error('未选择待审查的空间');
  if (!snlFile)
    throw new Error('未选择待审查的SNL文件');
  if (!rules || rules.length === 0)
    throw new Error('未选择待审查的条款');

  // 条文标签处理
  if(selectedSnlTags.length !== 0){
    const data = rebuildSnlFileContent(selectedSnlTags, snlFileData, rules);
    snlFile = new File([data], snlFile.name);
  }
  // 条文关联处理
  // 读取条文关联配置文件内容，转为字符串发送到后端
  if (relateSnlFile != null) {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (!snlFile)
        throw new Error('未选择待审查的SNL文件');
      let relateSnlStr = null;
      if (e.target != null && e.target.result != null) relateSnlStr = e.target.result.toString();

      ModelCheckerService.submitCheck(spaceId, checkName || snlFile.name.split('.')[0], snlFile, rules, use_filter, relateSnlStr, noIfCheck)
        .then(res => {
          message.success('成功提交审查, 请关注审查进度列表');
        })
        .catch(res => {
          console.error(res);
        });
    };
    reader.readAsText(relateSnlFile);
    return;
  }
  ModelCheckerService.submitCheck(spaceId, checkName || snlFile.name.split('.')[0], snlFile, rules, use_filter, null, noIfCheck)
    .then(res => {
      message.success('成功提交审查, 请关注审查进度列表');
    })
    .catch(res => {
      console.error(res);
    });
};

const rebuildSnlFileContent = (selectedSnlTags: string[], snlFileData: string, rules: string[]) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(snlFileData, 'text/xml');
  const ruleNodes = xmlDoc.querySelectorAll('rule');
  const removedRuleId = new Set();
  ruleNodes.forEach((ruleNode: Element) => {
    const ruleName = (ruleNode.querySelector('rule_number')?.textContent || '').trim();
    if (rules.indexOf(ruleName) === -1) {
      const ruleId = ruleNode.getAttribute('id') || '';
      removedRuleId.add(ruleId);
      ruleNode.parentElement?.removeChild(ruleNode);
    }
  });

  const fmlDescptNodes = xmlDoc.querySelectorAll('fml_descpt');

  fmlDescptNodes.forEach((fmlDescptNode: Element) => {
    const snlGroupText = (fmlDescptNode.querySelector('snl_group')?.textContent || '').trim();
    const ruleId = (fmlDescptNode.querySelector('ruleId')?.textContent || '').trim();

    // 分割 snlGroupText 成多个部分
    const snlGroupParts = snlGroupText ? snlGroupText.split(' ') : [];

    // 判断是否删除 fmlDescptNode
     const shouldRemoveNode =
      removedRuleId.has(ruleId) ||
      (!snlGroupText && !selectedSnlTags.includes('no-tag')) ||
      (snlGroupText && !snlGroupParts.some(part => selectedSnlTags.includes(part.trim())));
    if (shouldRemoveNode) {
      fmlDescptNode.parentElement?.removeChild(fmlDescptNode);
    }
  });
  return new XMLSerializer().serializeToString(xmlDoc);
};


const handleSingleSNLCheck = (spaceId: number | null, checkName: string | null, singleSNL: string) => {
  if (!spaceId)
    throw new Error('未选择待审查的空间');

  // 拼装SNL文件
  const snlFileContent = buildSingleSNL(singleSNL, '');
  const snlFile = new File([snlFileContent], '单条.snl');
  // 发送审查请求
  ModelCheckerService.submitCheck(spaceId, checkName || '单条审查', snlFile, ['1'], false, null, true)
    .then(res => {
      message.success('成功提交单条SNL审查, 请关注审查进度列表');
    })
    .catch(res => {
      console.error(res);
    });
};

const defaultCheckLayout: FlexLayout.IJsonModel = {
  global: {
    splitterSize: 4,
    splitterExtra: 4,
    tabSetEnableTabStrip: false,
    rootOrientationVertical: true,
  },
  borders: [],
  layout: {
    type: 'row',
    children: [
      {
        type: 'row',
        children: [
          {
            type: 'tabset',
            weight: 1,
            children: [
              {
                type: 'tab',
                id: 'check-submit',
                component: 'submit',
              },
            ],
          },
          {
            type: 'tabset',
            weight: 1,
            children: [
              {
                type: 'tab',
                id: 'check-result-list',
                component: 'result-list',
              },
            ],
          },
          {
            type: 'tabset',
            weight: 3,
            children: [
              {
                type: 'tab',
                id: 'check-report',
                component: 'report',
              },
            ],
          },
        ],
      },
    ],
  },
};

let checkLayout: FlexLayout.IJsonModel;

switch (process.env.REACT_APP_THEME) {
  case 'szzj':
    checkLayout = szzjCheckLayout;
    break;
  default:
    checkLayout = defaultCheckLayout;
    break;
}


function ModelChecker() {
  const spaces = useAppSelector(selectSpaces);
  const checkSpaceId = useAppSelector(selectCheckSpaceId);
  const checks = useAppSelector(selectChecks);
  const snls = useAppSelector(selectSNLs);
  const dispatch = useAppDispatch();

  // 通用数据
  const [checkName, setCheckName] = useState<string>(''); // 审查命名
  // 预设审查，名称数据
  const [presetName, setPresetName] = useState<string | null>(null);
  const [presetTagStr, setPresetTagStr] = useState<string>('');

  // 当前选择的Tab项
  const [snlTabKey, setSnlTabKey] = useState('preset');
  // 在线SNL审查，实质还是远端SNL下载到本地进行解析与条文选择等操作
  // 暂无
  // 本地SNL审查，需要存储SNL文件数据等
  const [snlFile, setSnlFile] = useState<File | null>(null); // SNL文件内容
  // SNL文件通用数据，条文编号选项
  const [snlLibrary, setSnlLibrary] = useState<SNLLibrary | null>(null); // SNL库对象
  const [ruleTree, setRuleTree] = useState<RuleTreeDataNode[]>([]); // SNL目录树
  const [tagTree, setTagTree] = useState<FieldDataNode<any>[]>([]); // SNL目录树
  const [selectedRules, setSelectedRules] = useState<string[]>([]); // SNL选择的条文编号列表
  const [selectedTags, setSelectedTags] = useState<string[]>([]); // SNL选择的条文Tag列表
  const [selectedSnlTags, setSelectedSnlTags] = useState<string[]>([]); // SNL选择的snl Tag列表
  const [snlFileData, setSnlFileData] = useState<string>(''); // snl文件内容
  // 单条SNL审查，存储单条文本即可，注意映射的添加
  const [singleSNL, setSingleSNL] = useState<string>('');
  const [useRelateSNLFilter, setUseRelateSNLFilter] = useState<boolean>(false)
  const [relateSNLConfigFile, setRelateSNLConfigFile] = useState<File | null>(null);
  const [relateSNLConfigFileStr, setRelateSNLConfigFileStr] = useState<string>("将使用默认条文关联配置文件");
  const [useRemoveDuplicate, setUseRemoveDuplicate] = useState<boolean>(true)
  const [noIfCheck, setNoIfCheck] = useState<boolean>(false)

  // 报告结果列表
  const [report, setReport] = useState<CheckReport | null>(null); // 审查报告结果
  const [selectedcheckId, setSelectedCheckId] = useState<string>('');
  const [resultTypeMap, setResultTypeMap] = useState({
    '3构件错误': true,
    '4硬性错误': true,
    '未通过': true,
  });

  // 布局
  const [layoutModel, setLayoutModel] = useState<FlexLayout.Model>(FlexLayout.Model.fromJson(checkLayout));

  // 监听大小变化，更新横纵模式
  const { width, height, ref } = useResizeDetector();
  const [form] = Form.useForm();

  const constructReportTree = (report: CheckReport, resultTypeMap: { [key: string]: boolean }) => {
    const tree: any[] = [];
    const errorTypeExcluded = ['1构件缺失','2属性缺失', '5不适用']
    // 进行数据预处理，包括自然排序
    report.ItemResults = Object.fromEntries(Object.entries(report.ItemResults).sort((a, b) => {
      const aHasErrors = a[1].CheckResults.some(checkResult => !checkResult.Pass  && !errorTypeExcluded.includes(checkResult.ErrorType));
      const bHasErrors = b[1].CheckResults.some(checkResult => !checkResult.Pass  && !errorTypeExcluded.includes(checkResult.ErrorType));

      if (aHasErrors && !bHasErrors) {
          return -1;
      } else if (!aHasErrors && bHasErrors) {
          return 1;
      }
      const aMatch = a[0].match(/(.*)\.s[np]l(.*)/);
      const bMatch = b[0].match(/(.*)\.s[np]l(.*)/);
      if (aMatch && bMatch) {
        const aRuleNumber = aMatch[2];
        const bRuleNumber = bMatch[2];

        const aLevels = aRuleNumber.split('.').map(n => parseInt(n));
        const bLevels = bRuleNumber.split('.').map(n => parseInt(n));
        const maxLevel = Math.max(aLevels.length, bLevels.length);
        for (let i = 0; i < maxLevel; i++) {
          const aLevel = i < aLevels.length ? aLevels[i] : 0;
          const bLevel = i < bLevels.length ? bLevels[i] : 0;
          if (aLevel !== bLevel) {
            return aLevel - bLevel;
          }
        }
        return 0;
      }
      return 0;
    }));

    resultTypeMap['5不适用'] = true;

    Object.entries(report.ItemResults).forEach(([key, itemResult]) => {
      const itemNameMatch = itemResult.Item.match(/(.*)\.s[np]l(.*)/);
      const affectedByRelateSNL = itemResult['affectedByRelateSNL']
      // 先过滤，再存储每个 SNL 对应的 CheckResult
      const groupedCheckResults = itemResult['CheckResults']
        .filter(checkResult => {
          if (resultTypeMap['只看被条文关联影响的条文'] && !affectedByRelateSNL)
            return false;
          if (!resultTypeMap['设计说明'] && checkResult['SNL'].includes('文本')) {
            return false;
          }
          return resultTypeMap[checkResult['ErrorType']];
        })
        .reduce((acc, checkResult) => {
          const snl = checkResult.SNL;
          acc[snl] = acc[snl] || [];
          acc[snl].push(checkResult);
          return acc;
        }, {} as { [key: string]: CheckResult[] });
      // 过滤掉所有不适用的snl
      const groupedFilterCheckResults = Object.keys(groupedCheckResults)
        .reduce((acc, snl) => {
          if (!groupedCheckResults[snl].every(cr => cr.ErrorType === '5不适用')) {
            acc[snl] = groupedCheckResults[snl];
          }
          return acc;
        }, {} as { [key: string]: CheckResult[] });

      const passCount = Object.keys(groupedFilterCheckResults).filter(c => groupedFilterCheckResults[c].every(cr => cr['Pass'])).length;
      const errorCount = Object.keys(groupedFilterCheckResults).filter(c => groupedFilterCheckResults[c].some(cr => !cr['Pass'])).length
      const notApplicableCount = Object.keys(groupedCheckResults).length - Object.keys(groupedFilterCheckResults).length;

      if (itemNameMatch) {
        const fileName = itemNameMatch[1];
        const ruleNumber = itemNameMatch[2];
        const itemName = itemNameMatch[2] + ' - ' + itemNameMatch[1];
        let node = tree.find(n => n.name === fileName);
        if (!node) {
          node = {
            id: fileName, name: fileName, children: [], totalCount: 0, totalErrorCount: 0
          };
          tree.push(node);
        }
        node.children.push({
          id: itemName, name: (
            <span>
              {
                errorCount === 0 ?
                  <CheckCircleFilled title="通过" style={{color: 'green'}}/> :
                  <CloseCircleFilled title="未通过" style={{color: 'red'}}/>
              }{' '}
              {ruleNumber}{' '}
              {passCount !== 0 && <Badge title="通过" count={passCount} showZero color="#22ff22"/>}{' '}
              {errorCount !== 0 && <Badge title="未通过" count={errorCount} color="#ff2222" />}{' '}
              {notApplicableCount !== 0 && <Badge title="不适用" count={`${notApplicableCount}`} color="#aaa" />}
            </span>
          ),
        });
        node.totalCount += 1;
        node.totalErrorCount += errorCount > 0 ? 1 : 0 ;
      }
    });
    return tree;
  };

  const reportTree = useMemo(() => {
    return report ? constructReportTree(report, resultTypeMap) : [];
  }, [report, resultTypeMap]);

  const onCheckClick = (checkId: string) => {
    Promise.all([
      ModelCheckerService.getCheckResult(checkId),
      ModelCheckerService.getCheckElements(checkId),
      ModelCheckerService.getCheckGeoms(checkId),
    ])
      .then(([resultsRes, elementsRes, geomsRes]) => {
        setReport(resultsRes.data);
        setSelectedCheckId(checkId);
        dispatch(addElements(elementsRes.data));
        dispatch(addGeoms(geomsRes.data));
      })
      .catch(res => {
        console.error(res);
        message.error('获取报告结果出错, 请等待审查完成');
      });
  };

  const refreshReport = () => {
    ModelCheckerService.getCheckResult(selectedcheckId)
      .then(res => {
        setReport(res.data);
      })
      .catch(res => {
        console.error(res);
        message.error('获取报告结果出错, 请等待审查完成');
      });
  };
  useEffect(() => {
    if (width && height) {
      if (width > height) {
        layoutModel.doAction(
          FlexLayout.Actions.updateModelAttributes({
            rootOrientationVertical: true,
          }),
        );
      } else {
        layoutModel.doAction(
          FlexLayout.Actions.updateModelAttributes({
            rootOrientationVertical: false,
          }),
        );
      }
    }
  }, [width, height]);

  const tagTreeData = useMemo( () => {
    return snlLibrary?.getAntdTagsTree() || [];
  }, [snlLibrary]);

  const ruleTreeData = useMemo(() => {
    return snlLibrary ? [snlLibrary?.getAntdTree(selectedTags, selectedSnlTags)] : [];
  }, [snlLibrary, selectedTags, selectedSnlTags]);

  const snlTagTreeData = useMemo( () => {
    return snlLibrary?.getAntdSnlTagsTree() || [];
  }, [snlLibrary]);

  const selectRelateSNLConfigFile = (e: any) => {
    const file = e.target.files[0];
    if (file == null) return;
    setRelateSNLConfigFile(file);
    setRelateSNLConfigFileStr("将使用" + file.name + "作为条文关联配置文件");
  };

  const selectRelateSNLConfigFileButton = (inputElementId: string) => {
    const fileInput = document.getElementById(inputElementId);
    if (fileInput) {
      fileInput.click();
    }
  };

  const resetRelateSNLConfigFile = () => {
    setRelateSNLConfigFile(null);
    setRelateSNLConfigFileStr("将使用默认条文关联配置文件");
  }

  return (
    <div ref={ref} className={styles.wrapper}>
      <FlexLayout.Layout
        model={layoutModel}
        font={{size: '12px'}}
        // realtimeResize
        factory={(node) => {
          switch (node.getComponent()) {
            case 'submit':
              return (
                <div className={styles.submit}>
                  {/*当前视图审查（全部视图用3d等等）（加一个tab页面放log）（再放一个tab放任务队列可视化）*/}
                  <Space direction="vertical" style={{width: '100%'}}>
                    <Input
                      placeholder="本次审查命名"
                      style={{textAlign: 'center'}}
                      value={checkName}
                      onChange={e => setCheckName(e.target.value)}
                    />
                    <Space.Compact direction="horizontal" style={{width: '100%'}}>
                      <Select
                        style={{width: '100%'}}
                        showSearch
                        placeholder="选择审查视图"
                        optionFilterProp="children"
                        onChange={id => dispatch(setCheckSpaceId(id))}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        value={checkSpaceId}
                        options={
                          spaces.map(space => {
                            return {
                              value: space.spaceId,
                              label: `${space.name} (${space.building})`,
                            }
                          })
                        }
                      />
                    </Space.Compact>
                    <Space.Compact direction="horizontal" style={{width: '100%'}}>
                      <Tabs
                        style={{width: '100%'}}
                        centered
                        size="small"
                        activeKey={snlTabKey}
                        onChange={k => {
                          // 清空选择的SNL条款列表，避免切换SNL后，条款错误
                          // 同时将使用条文关联设置为 false
                          setSelectedRules([]);
                          setSnlTabKey(k);
                          setUseRelateSNLFilter(false);
                          setUseRemoveDuplicate(true);
                          setNoIfCheck(false);
                        }}
                        items={[
                          {label: '预设审查', key: 'preset', children: (
                              <Space direction="vertical" style={{width: '100%'}}>
                                <Select
                                  style={{width: '100%'}}
                                  showSearch
                                  placeholder="预设审查配置"
                                  optionFilterProp="children"
                                  value={presetName}
                                  onChange={v => setPresetName(v)}
                                  filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                  }
                                  options={snls.presets.map(({name}) => ({
                                    label: name, value: name,
                                  }))}
                                />
                                <Select
                                  mode="tags"
                                  style={{ width: '100%' }}
                                  onChange={(value) => {
                                    // 直接存储逗号分隔的tag列表即可，需要后端处理
                                    setPresetTagStr(value.join(','));
                                  }}
                                  tokenSeparators={[',']}
                                  options={[
                                    {label: '可审查', value: '可审查'},
                                    {label: '暂不支持', value: '暂不支持'},
                                    {label: '未编制', value: '未编制'},
                                  ]}
                                />
                              <div>
                                <Checkbox checked={useRelateSNLFilter} onChange={(e) => {
                                  setUseRelateSNLFilter(e.target.checked);
                                  resetRelateSNLConfigFile();
                                }}>
                                  使用条文关联过滤
                                </Checkbox>
                                <Checkbox
                                  checked={useRemoveDuplicate}
                                  onChange={e => {
                                    setUseRemoveDuplicate(!useRemoveDuplicate);
                                  }}>使用条文去重
                                </Checkbox>
                                <Checkbox checked={noIfCheck} onChange={(e) => {
                                  setNoIfCheck(e.target.checked);
                                }}>
                                  关闭 if 审查
                                </Checkbox>
                              </div>
                                <input id="relate-snl-input-pre" type="file" accept=".json" onChange={selectRelateSNLConfigFile} style={{ display: 'none' }}/>
                                { useRelateSNLFilter ?
                                  <Button size={"small"} onClick={() => {selectRelateSNLConfigFileButton("relate-snl-input-pre")}}>
                                    上传条文关联配置文件
                                  </Button> : ''
                                }
                                { useRelateSNLFilter && relateSNLConfigFile != null ?
                                  <Button size={"small"} onClick={() => {resetRelateSNLConfigFile()}}>
                                    重置为默认条文关联配置文件
                                  </Button> : ''
                                }
                                { useRelateSNLFilter ?
                                  <Button size={"small"} onClick={() => {window.open('https://git.thubimchecker.com/fpchecker/tuzhi-snl/-/blob/main/relate_snl_config.json?ref_type=heads')}}>
                                    查看条文关联默认配置文件
                                  </Button> : ''
                                }
                                {
                                    useRelateSNLFilter ?
                                        relateSNLConfigFileStr : ''
                                }
                              </Space>
                            )},
                          {label: '在线SNL', key: 'online-snl', children: (
                            <div> <OnlineSNLForm form={form} />
                              <Space.Compact direction="vertical" style={{ width: '100%' }}>
                                <div>
                                  <Checkbox checked={useRelateSNLFilter} onChange={(e) => {
                                    resetRelateSNLConfigFile()
                                    setUseRelateSNLFilter(e.target.checked);
                                  }}>
                                    使用条文关联过滤
                                  </Checkbox>
                                  <Checkbox
                                    checked={useRemoveDuplicate}
                                    onChange={e => {
                                      setUseRemoveDuplicate(!useRemoveDuplicate);
                                    }}>使用条文去重</Checkbox>
                                  <Checkbox checked={noIfCheck} onChange={(e) => {
                                    setNoIfCheck(e.target.checked);
                                  }}>
                                    关闭 if 审查
                                  </Checkbox>
                                </div>
                                <br/>
                                <input id="relate-snl-input-online" type="file" accept=".json" onChange={selectRelateSNLConfigFile} style={{ display: 'none' }}/>
                                { useRelateSNLFilter ?
                                  <Button size={"small"} onClick={() => {selectRelateSNLConfigFileButton("relate-snl-input-online")}}>
                                    上传条文关联配置文件
                                  </Button> : ''
                                }
                                { useRelateSNLFilter && relateSNLConfigFile != null ?
                                  <Button size={"small"} onClick={() => {resetRelateSNLConfigFile()}}>
                                    重置为默认条文关联配置文件
                                  </Button> : ''
                                }
                                { useRelateSNLFilter ?
                                  <Button size={"small"} onClick={() => {window.open('https://git.thubimchecker.com/fpchecker/tuzhi-snl/-/blob/main/relate_snl_config.json?ref_type=heads')}}>
                                    查看条文关联默认配置文件
                                  </Button> : ''
                                }
                                {
                                  useRelateSNLFilter ?
                                    relateSNLConfigFileStr : ''
                                }
                              </Space.Compact>
                            </div>
                            )},
                          {label: '本地SNL', key: 'local-snl', children: (
                              <Space.Compact direction="vertical" style={{width: '100%'}}>
                                <Upload
                                  name="snl-file"
                                  accept="*.snl"
                                  className={styles.localSnl}
                                  showUploadList={false}
                                  beforeUpload={(file, fileList) => {
                                    try {
                                      setSelectedRules([]);
                                      setSelectedTags([]);
                                      setSelectedSnlTags([]);
                                      setSnlFile(file);
                                      const reader = new FileReader();
                                      reader.onload = (e) => {
                                        if (e?.target?.result && typeof e?.target?.result === 'string') {
                                          setSnlFileData(e.target.result);
                                          const snlLibrary = SNLLibrary.loadFromXMLString(e.target.result);
                                          setSnlLibrary(snlLibrary);
                                          setSelectedTags(Object.values(snlLibrary.tags).map(t => t.id));
                                        }
                                      };
                                      reader.readAsText(file);
                                      return false;
                                    } catch (e) {
                                      console.error(e);
                                      return false;
                                    }
                                  }}
                                >
                                  <Button
                                    style={{width: '100%'}}
                                    icon={<UploadOutlined />}
                                  >
                                    本地SNL
                                  </Button>
                                </Upload>
                                <Space.Compact direction="horizontal" style={{width: '100%'}}>
                                 <TreeSelect
                                   style={{
                                     width: '50%',
                                   }}
                                   treeData={tagTreeData}
                                   value={selectedTags}
                                   onChange={(newValue) => {
                                     setSelectedTags(newValue);
                                     setSelectedSnlTags([]);
                                     setSelectedRules([]);
                                     console.log(newValue);
                                   }}
                                   treeCheckable={true}
                                   placeholder="选择筛选条文标签"
                                   treeLine
                                   autoClearSearchValue
                                   treeNodeFilterProp="name"
                                   treeDefaultExpandAll
                                   showCheckedStrategy={TreeSelect.SHOW_CHILD}
                                   maxTagCount={0}
                                   maxTagPlaceholder={(v) => <span>{`已选择${v.length}个标签`}</span>}
                                   listHeight={400}
                                 />
                                 <TreeSelect
                                    style={{
                                      width: '50%',
                                    }}
                                    treeData={snlTagTreeData}
                                    value={selectedSnlTags}
                                    onChange={(newValue) => {
                                      setSelectedSnlTags(newValue);
                                      setSelectedTags([]);
                                      setSelectedRules([]);
                                      console.log(newValue);
                                    }}
                                    treeCheckable={true}
                                    placeholder="选择筛选snl标签"
                                    treeLine
                                    autoClearSearchValue
                                    treeNodeFilterProp="name"
                                    treeDefaultExpandAll
                                    showCheckedStrategy={TreeSelect.SHOW_CHILD}
                                    maxTagCount={0}
                                    maxTagPlaceholder={(v) => <span>{`已选择${v.length}个标签`}</span>}
                                    listHeight={400}
                                  />
                                </Space.Compact>
                                <TreeSelect
                                  style={{
                                    width: '100%',
                                  }}
                                  treeData={ruleTreeData}
                                  value={selectedRules}
                                  onChange={(newValue) => {
                                    setSelectedRules(newValue);
                                  }}
                                  treeCheckable={true}
                                  placeholder="选择审查规范编号"
                                  treeLine
                                  autoClearSearchValue
                                  treeNodeFilterProp="title"
                                  treeDefaultExpandAll
                                  showCheckedStrategy={TreeSelect.SHOW_CHILD}
                                  maxTagCount={0}
                                  maxTagPlaceholder={(v) => <span>{`已选择${v.length}条`}</span>}
                                  listHeight={400}
                                />
                              </Space.Compact>
                            )},
                          {label: '单条SNL', key: 'single-snl', children: (
                              <div>
                                <Input.TextArea
                                  rows={4}
                                  placeholder="手写单条SNL直接审查,功能已实现,映射编辑暂未开放"
                                  value={singleSNL}
                                  onChange={e => setSingleSNL(e.target.value)}
                                />
                              </div>
                            )},
                        ]}
                      />
                    </Space.Compact>
                    <Space.Compact style={{width: '100%'}}>
                      <Popconfirm
                        placement="top"
                        okText="是"
                        cancelText="否"
                        title={(
                          <div>
                            <p>当前审查方式: {{
                              'preset': '预设审查',
                              'online-snl': '在线SNL',
                              'local-snl': '本地SNL',
                              'single-snl': '单条SNL',
                            }[snlTabKey]}</p>
                            <p>{snlFile?.name && `当前SNL: ${snlFile?.name}`}</p>
                            <p>
                              {
                                snlTabKey === 'preset' && presetName &&
                                <span>
                          当前选择预设审查: {presetName}, 内容包括: {snls.presets.find(p => p.name === presetName)?.rules}
                        </span>
                              }
                              {
                                ['online-snl', 'local-snl'].includes(snlTabKey) && selectedRules.length > 0 &&
                                <span>
                          当前选择条款实际共{selectedRules.filter(r => !r.includes('root')).length}条: <br/>
                                  {selectedRules.filter(r => !r.includes('root')).join(', ')}
                        </span>
                              }
                              {
                                useRelateSNLFilter &&
                                <span>
                                <br/><br/>已开启条文关联过滤
                              </span>
                              }
                            </p>
                            <p>是否确认提交审查?</p>
                          </div>
                        )}
                        onConfirm={() => {
                          try {
                            switch (snlTabKey) {
                              case 'preset':
                                handlePresetCheck(checkSpaceId, checkName, presetName, presetTagStr, useRelateSNLFilter, relateSNLConfigFile, useRemoveDuplicate, noIfCheck);
                                break;
                              case 'online-snl':
                                if (useRelateSNLFilter) handleOnlineSnlCheck(checkSpaceId, checkName, true, relateSNLConfigFile, form, useRemoveDuplicate, noIfCheck);
                                else handleOnlineSnlCheck(checkSpaceId, checkName, false, null, form, useRemoveDuplicate, noIfCheck);
                                break;
                              case 'local-snl':
                                if (useRelateSNLFilter) handleCheck(checkSpaceId, checkName, snlFile, selectedRules, true, snlFileData, selectedSnlTags, relateSNLConfigFile, true);
                                else handleCheck(checkSpaceId, checkName, snlFile, selectedRules, false, snlFileData, selectedSnlTags, null, true);
                                break;
                              case 'single-snl':
                                handleSingleSNLCheck(checkSpaceId, checkName, singleSNL);
                                break;
                            }
                          } catch (e: any) {
                            message.error(e.message);
                          }
                        }}
                      >
                        <Button type="primary" block size="small">
                          提交审查
                        </Button>
                      </Popconfirm>
                    </Space.Compact>
                  </Space>
                </div>
              );
            case 'result-list':
              return (
                <div className={styles.resultList}>
                  <List
                    header={<div>结果列表</div>}
                    // bordered
                    dataSource={checks}
                    renderItem={c => {
                      // todo 当前默认空间只有一个
                      const itemSpace = c.spaceIds.flatMap(i => spaces.find(s => s.spaceId == i))[0];
                      if (!itemSpace)
                        return null;
                      return (
                        <List.Item className={styles.resultListItem}>
                          <div
                            className={styles.resultListTitle}
                            onClick={e => onCheckClick(c.checkId)}
                          >
                            <div style={{fontSize: 16, fontWeight: 'bold', cursor: 'pointer'}}>
                              {c.checkName}
                            </div>
                            <div className={styles.detail}>
                              <div className={styles.funcWrapper}>
                                <div>
                                  <Tooltip placement="left" title={`${itemSpace.treePath.join('/')}/${itemSpace.name}`}>
                                    <b>空间</b>{' '}
                                    {c.spaceIds.flatMap(i => spaces.find(s => s.spaceId == i)?.name)}
                                  </Tooltip>
                                </div>
                                <div>
                                  <Tooltip placement="left" title={c.snlNames}>
                                    <b>SNL</b>{' '}
                                    {c.snlNames}
                                  </Tooltip>
                                </div>
                                <div className={styles.group}>
                                  <Button.Group size="small">
                                    <Tooltip placement="top" title="下载BCJSON模型">
                                      <Button
                                        onClick={() => {
                                          console.log(233);
                                          ModelCheckerService.getCheckBCJSON(c.checkId)
                                            .then(res => {
                                              const blob = new Blob([JSON.stringify(res.data)], {type: 'text/json;charset=utf-8'});
                                              saveAs(blob, 'model.json');
                                              message.success('被审查模型下载成功');
                                            });
                                        }}
                                      >
                                        <img src={boxImg} />
                                      </Button>
                                    </Tooltip>
                                    <Tooltip placement="top" title="下载报告JSON数据">
                                      <Button
                                        onClick={() => {
                                          ModelCheckerService.getCheckResult(c.checkId)
                                            .then(res => {
                                              console.log(res.data);
                                              const blob = new Blob([JSON.stringify(res.data)], {type: 'text/json;charset=utf-8'});
                                              saveAs(blob, 'report.json');
                                              message.success('审查报告JSON下载成功');
                                            });
                                        }}
                                      >
                                        <img src={jsonImg} />
                                      </Button>
                                    </Tooltip>
                                    <Tooltip placement="top" title="下载报告CSV数据">
                                      <Button
                                        onClick={() => {
                                          ModelCheckerService.getCheckResult(c.checkId)
                                            .then(res => {
                                              const csv = reportToCsv(res.data);
                                              const blob = new Blob([csv], {type: 'text/csv;charset=utf-8'});
                                              saveAs(blob, 'report.csv');
                                              message.success('审查报告CSV下载成功');
                                            });
                                        }}
                                      >
                                        <img src={csvImg} />
                                      </Button>
                                    </Tooltip>
                                    <Tooltip placement="top" title="下载PDF数据">
                                      <Button
                                        onClick={() => {
                                          ModelCheckerService.getCheckResultPDF(c.checkId)
                                            .then(res => {
                                              saveAs(res.data, `${c.checkName}-审查报告.pdf`);
                                              message.success('审查报告PDF下载成功');
                                            });
                                        }}
                                      >
                                        <img src={pdfImg} />
                                      </Button>
                                    </Tooltip>
                                  </Button.Group>
                                </div>
                                <div>
                                  {/*模态框，当选择推送审查结果时，展示所有的数据，并提供确认按钮，一键推送到清大东方接口*/}
                                  <Button.Group size="small">
                                    {/*<Tooltip placement="top" title="推送审查结果">*/}
                                    {/*  <Button*/}
                                    {/*    type="text"*/}
                                    {/*    // icon={<CloudUploadOutlined />}*/}
                                    {/*    onClick={() => {*/}
                                    {/*      ModelCheckerService.submitToQingDa(c.checkId)*/}
                                    {/*        .then(res => {*/}
                                    {/*          message.success('推送成功');*/}
                                    {/*          // window.prompt('推送结果', res.data.payload);*/}

                                    {/*          const newWindow = window.open('', '_blank');*/}
                                    {/*          if (newWindow) {*/}
                                    {/*            newWindow.document.write('<html><head><title>新的空白页</title></head><body>');*/}
                                    {/*            newWindow.document.write('<pre>' + res.data.payload + '</pre>');*/}
                                    {/*            newWindow.document.close();*/}
                                    {/*          }*/}
                                    {/*        });*/}
                                    {/*    }}*/}
                                    {/*  >*/}
                                    {/*    推送结果*/}
                                    {/*  </Button>*/}
                                    {/*</Tooltip>*/}

                                    <Tooltip placement="top" title="导出采纳意见">
                                      <Button
                                        onClick={() => {
                                          ModelCheckerService.getAcceptedCheckResult(c.checkId)
                                            .then(res => {
                                              console.log(res.data);
                                              const blob = new Blob([JSON.stringify(res.data)], {type: 'text/json;charset=utf-8'});
                                              saveAs(blob, 'report.json');
                                              message.success('审查报告JSON下载成功');
                                            });
                                        }}
                                      >
                                        导出意见
                                      </Button>
                                    </Tooltip>
                                  </Button.Group>
                                </div>
                              </div>
                              <div className={styles.timeWrapper}>
                                <div className={styles.resultListIcon}>
                                  {
                                    <Progress percent={c.finishTime ? 100 : 40} />
                                  }
                                </div>
                                <div><b>提交</b> {formatDate(c.createTime)}</div>
                                {
                                  c.finishTime &&
                                  <div>
                                    <b>完成</b> {formatDate(c.finishTime)}
                                    {/*<br/>或者显示日志*/}
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        </List.Item>
                      );
                    }}
                  />
                </div>
              );
            case 'report':
              return (
                <div className={styles.report}>
                  <div className={styles.checks}>
                    {
                      process.env.REACT_APP_THEME !== 'szzj' && <ResultTypeList
                      resultTypeMap={resultTypeMap}
                      // todo
                      //@ts-ignore
                      setResultTypeMap={setResultTypeMap}
                      />
                    }
                  </div>
                  <div className={styles.main}>
                    <div className={styles.tree}>
                      {
                        report && <TuzhiTree
                          data={reportTree}
                        />
                      }
                    </div>
                    <div className={styles.list}>
                      <CheckReportViewer
                        bcResult={report} showTypeList={resultTypeMap} refreshReport={refreshReport}checkId={selectedcheckId}
                      />
                    </div>
                  </div>
                </div>
              );
            default:
              return <div>默认</div>;
          }
        }}
      />
    </div>
  );
}

export default ModelChecker;