import React from "react";
import { Row, Col, Button, Upload, message, InputNumber } from "antd";
import type { UploadProps } from "antd";
import { useState, useEffect } from "react";
import styles from "./ConsistencyAsset.module.less";
import { API_BASE_URL } from "../../../config";
import { store } from "../../../app/store";
import ConsistencyViewerService from "../ConsistencyViewer.service";
import {
  setProjectId,
  setElevation,
  selectTuZhiProjectId,
  setTuZhiProjectId,
  clearConsistencyCache
} from "../consistencyViewerSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { prev } from "cheerio/lib/api/traversing";
import { setUploadIfc } from "../consistencyViewerSlice";

interface ConsistencyAssetProps {
  projectId: number;
}

function ConsistencyAsset(props: ConsistencyAssetProps) {
  const dispatch = useAppDispatch();
  const { projectId } = props;
  const curStore = store.getState();
  const token = curStore?.auth?.token;
  const [consistency_asset1, setConsistencyAsset1] = useState(false);
  const [consistency_asset2, setConsistencyAsset2] = useState(false);
  const [consistency_asset3, setConsistencyAsset3] = useState(false);
  const [consistency_asset4, setConsistencyAsset4] = useState(false);
  const [tuzhiId, setTuzhiId] = useState("无");
  const [newTuzhiProjectId, setNewTuzhiProjectId] = useState<number>(1);
  const tuzhiProjectId = useAppSelector(selectTuZhiProjectId);

  useEffect(() => {
    dispatch(clearConsistencyCache());
  }, []);

  useEffect(() => {
    ConsistencyViewerService.getConsistencyAsset(projectId).then((res: any) => {
      setConsistencyAsset1((prev) => res.data[0] == true);
      setConsistencyAsset2((prev) => res.data[1] == true);
      setConsistencyAsset3((prev) => res.data[2] == true);
      setConsistencyAsset4((prev) => res.data[3] == true);
    });
  }, []);

  useEffect(() => {
      setTuzhiId(tuzhiProjectId?.toString() || "无");
      setConsistencyAsset1(true);
  },[tuzhiProjectId]);

  const uploda_props: Array<UploadProps> = [
    {
      name: "res",
      action: `${API_BASE_URL}/consistency/ifc`,
      accept: "application/json",
      multiple: false,
      data: {
        projectId: projectId,
        type: "PDFBCJSON",
      },
      maxCount: 1,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      onChange(info) {
        if (info.file.status !== "uploading") {
          console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
          setConsistencyAsset2((prev) => true);
          message.success(`${info.file.name}上传成功`);
        } else if (info.file.status === "error") {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    },
    {
      name: "res",
      action: `${API_BASE_URL}/consistency/ifc`,
      accept: "application/zip",
      multiple: false,
      data: {
        projectId: projectId,
        type: "PDFZIP",
      },
      maxCount: 1,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      onChange(info) {
        if (info.file.status !== "uploading") {
          console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
          setConsistencyAsset1((prev) => true);
          message.success(`${info.file.name}上传成功`);
        } else if (info.file.status === "error") {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    },
    {
      name: "res",
      action: `${API_BASE_URL}/consistency/ifc`,
      accept: "application/json",
      multiple: false,
      data: {
        projectId: projectId,
        type: "IFCBCJSON",
      },
      maxCount: 1,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      onChange(info) {
        if (info.file.status !== "uploading") {
          console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
          setConsistencyAsset3((prev) => true);
          message.success(`${info.file.name}上传成功`);
        } else if (info.file.status === "error") {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    },
    {
      name: "res",
      action: `${API_BASE_URL}/consistency/ifc`,
      accept: ".ifc",
      multiple: false,
      data: {
        projectId: projectId,
        type: "IFCZIP",
      },
      maxCount: 1,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      onChange(info) {
        if (info.file.status !== "uploading") {
          console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
          setConsistencyAsset4((prev) => true);
          message.success(`${info.file.name}上传成功`);
          // 等待1秒后执行 dispatch(setUploadIfc(true))
          setTimeout(() => {
            dispatch(setUploadIfc(true));
          }, 1000); // 1000毫秒 = 1秒
        } else if (info.file.status === "error") {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    },
  ];
  return (
    <div className={styles.root}>
      <Row style={{ marginBottom: "10px" }}>
        <Col span={16} className={styles.singleCol}>
          <div>当前图智项目ID</div>
        </Col>
        <Col
          span={8}
          className={styles.singleCol}
          style={{ color: consistency_asset1 ? "green" : "blue" }}
        >
          <div>{tuzhiId}</div>
        </Col>
      </Row>
      <Row style={{ marginBottom: "10px" }}>
        <Col span={10} className={styles.singleCol}>
          <div>修改ID</div>
        </Col>
        <Col
            span={6}
            className={styles.singleCol}
        >
            <InputNumber
                min={1}
                max={1000000}
                size="small"
                value={newTuzhiProjectId ?? 0}
                onChange={(value)=>setNewTuzhiProjectId(value ?? 0)}
            />
        </Col>
        <Col span={2}></Col>
        <Col span={6} className={styles.singleCol}>
          <Button
            type="primary"
            onClick={() => {
              ConsistencyViewerService.modifyConsistenyTuzhiId(
                projectId,
                newTuzhiProjectId
              ).then((res: any) => {
                setTuzhiId((prev) => newTuzhiProjectId.toString());
                dispatch(setTuZhiProjectId(newTuzhiProjectId));
              });
            }}
          >
            提交
          </Button>
        </Col>
      </Row>
      <Row style={{ marginBottom: "10px" }}>
        <Col span={11} className={styles.singleCol}>
          <div>图纸比对资源</div>
        </Col>
        <Col
          span={6}
          className={styles.singleCol}
          style={{ color: consistency_asset2 ? "green" : "blue" }}
        >
          <div>{consistency_asset2 ? "已完成" : "未完成"}</div>
        </Col>
        <Col span={7} className={styles.singleCol}>
          <Upload {...uploda_props[0]} showUploadList={false}>
            <Button type="primary">提交</Button>
          </Upload>
        </Col>
      </Row>
      <Row style={{ marginBottom: "10px" }}>
        <Col span={11} className={styles.singleCol}>
          <div>模型比对资源</div>
        </Col>
        <Col
          span={6}
          className={styles.singleCol}
          style={{ color: consistency_asset3 ? "green" : "blue" }}
        >
          <div>{consistency_asset3 ? "已完成" : "未完成"}</div>
        </Col>
        <Col span={7} className={styles.singleCol}>
          <Upload {...uploda_props[2]} showUploadList={false}>
            <Button type="primary">提交</Button>
          </Upload>
        </Col>
      </Row>
      <Row>
        <Col span={11} className={styles.singleCol}>
          <div>模型展示资源</div>
        </Col>
        <Col
          span={6}
          className={styles.singleCol}
          style={{ color: consistency_asset4 ? "green" : "blue" }}
        >
          <div>{consistency_asset4 ? "已完成" : "未完成"}</div>
        </Col>
        <Col span={7} className={styles.singleCol}>
          <Upload {...uploda_props[3]} showUploadList={false}>
            <Button type="primary">提交</Button>
          </Upload>
        </Col>
      </Row>
    </div>
  );
}

export default ConsistencyAsset;
