import React from 'react';

const Unauthorized: React.FC = () => {
  return (
    <div>
      <h1>未授权访问</h1>
      <p>您没有权限访问此页面。</p>
    </div>
  );
};

export default Unauthorized;