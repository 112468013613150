let baseURL;

// if (process.env.REACT_APP_BACKEND_URL) {
//   console.log('采用自定义后端API模式', process.env.REACT_APP_BACKEND_URL);
//   baseURL = process.env.REACT_APP_BACKEND_URL;
// } else {
//   if (process.env.REACT_APP_BACKEND_TYPE === 'dev') {
//     baseURL = '//127.0.0.1:10888'; // todo
//   } else if (process.env.REACT_APP_BACKEND_TYPE === 'public') {
//     baseURL = '//real.tuzhi.ai:34443';  // 公开版本也使用真实服务器
//   } else if (process.env.REACT_APP_BACKEND_TYPE === 'local' || !process.env.REACT_APP_BACKEND_TYPE) {
//     process.env.REACT_APP_BACKEND_TYPE = 'local';
//     baseURL = '//127.0.0.1:10888';
//   }
// }

// 修改为根据当前前端页面地址，动态映射后端API地址
const hostMap: {
  [host: string]: {
    api: string,
    name: string,
  }

} = {
  'localhost:4091': {
    api: '//127.0.0.1:10888', name: '图智本地开发平台',
  },
  '127.0.0.1:4091': {
    api: '//127.0.0.1:10888', name: '图智本地开发平台',
  },
  'next.tuzhi.ai': {
    api: '//next-api.tuzhi.ai', name: '图智清华测试平台',
  },
  'dev.tuzhi.ai': {
    api: '//dev-api.tuzhi.ai', name: '图智清大东方测试平台',
  },
  'tuzhi-dev.qddf.tech:21080': {
    api: '//tuzhi-dev-api.qddf.tech:21043', name: '图智清大东方测试平台',
  },
  'qdzn.tuzhi.ai': {
    api: '//qdzn-api.tuzhi.ai', name: '图智清大东方安徽正式平台',
  },
  'tuzhi-ah.qddf.tech:21180': {
    api: '//tuzhi-ah-api.qddf.tech:21143', name: '图智清大东方安徽正式平台',
  },
  'tuzhi-demo.tuzhi.ai': {
    api: '//tuzhi-demo-api.tuzhi.ai', name: '图智清华演示平台',
  },
  'tuzhi-demo.qddf.tech:21280': {
    api: '//tuzhi-demo.qddf.tech:21243', name: '图智清华演示平台',
  },
  'qdzn-demo.tuzhi.ai': {
    api: '//qdzn-demo-api.tuzhi.ai', name: '图智清大东方演示平台',
  },
  'qdzn-demo.qddf.tech:21380': {
    api: '//qdzn-demo.qddf.tech:21343', name: '图智清大东方演示平台',
  },
  'qdzn-guangdong.tuzhi.ai': {
    api: '//qdzn-guangdong-api.tuzhi.ai', name: '图智清大东方广东生产环境',
  },
  'qdzn-guangdong.qddf.tech:21480': {
    api: '//qdzn-guangdong.qddf.tech:21443', name: '图智清大东方广东生产环境',
  },
};

// export const API_BASE_URL = baseURL;
// export const API_BASE_URL = hostMap[window.location.host]?.api || '/api';  // 默认使用nginx转发的/api路径
export const API_BASE_URL = hostMap[window.location.host]?.api || (process.env.PUBLIC_URL || '') + '/api';  // 默认使用nginx转发的/api路径
export const API_NAME = hostMap[window.location.host]?.name || '图智未授权环境';