import React from 'react';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { selectUser } from '../auth/authSlice';

export default function LeftMenu() {
  const { md } = useBreakpoint();
  const user = useAppSelector(selectUser);
  const menuItems = [
    {
      key: 'home',
      label: (
        <Link to="/">
          平台介绍
        </Link>
      )
    },
    {
      key: 'projects',
      label: (
        <Link to="/projects">
          项目列表
        </Link>
      )
    },
    ...(user.role !== 'UserRole.TRIAL_USER' ? [{
      key: 'snl',
      label: (
        <Link to="/snl">
          在线SNL
        </Link>
      )
    }] : [])
  ];
  
  return (
    <Menu theme="dark" mode={md ? 'horizontal' : 'inline'} items={menuItems} />
  );
}
