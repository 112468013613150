import React, { useEffect, useState } from 'react';

import { List, Card, Button, Input, notification } from 'antd';
import type { SearchProps } from 'antd/lib/input';
import styles from './ConsistencyList.module.less';
import ConsistencyListService from './ConsistencyList.service';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

const { Search } = Input;

export interface ConsistencyProjectItem {
  projectId: number;
  name: string;
  createTime: string;
}

interface TianhuaConsistencyListProps {
  username: string;
}

function TianhuaConsistencyList(props: TianhuaConsistencyListProps) {
  const navigate = useNavigate();
  // 新建项目名称
  const [data, setData] = useState<ConsistencyProjectItem[]>([]); //data是一个数组，数组中的每个元素是一个对象，对象的属性有['projectName', 'createTime'
  useEffect(() => {
    ConsistencyListService.getConsistencyProjects()
      .then(res => {
        console.log(res.data);
        setData(res.data);
      });
  }, []); // Empty dependency array to run this effect only once
  const onAddProject: SearchProps['onSearch'] = (value: string, _e: any) => {
    if(value === ''){
      return;
    }
    ConsistencyListService.createConsistencyProject(value)
    .then(res => {
      console.log(res.data);
      notification['success']({
        message: '新建项目成功',
        description:
          `项目 “${value}” 已成功创建。`,
      });
      const newData = [...data];
      newData.unshift({
        projectId: res.data["projectId"],
        name: res.data["name"],
        createTime: res.data["createTime"],
      });
      setData(newData);
    })
    .catch(res => {
      notification['error']({
        message: '新建项目失败',
        description:
          `新建项目 “${name}” 失败，请检查网络。`,
      });
    });
    //在data数组最前面加入一个新的项目
  };
  return (
    <div className={styles.root}>
      <Card className={styles.projectListCard}>
        <div className={styles.cardTitle}>
          图模一致性项目列表
        </div>
        <div className={styles.searchWrap}>
          <Search
            placeholder="创建项目名称"
            allowClear
            enterButton="创建"
            size="middle"
            onSearch={onAddProject}
          />
        </div>
        <List
          itemLayout="horizontal"
          className={styles.projectList}
          dataSource={data}
          renderItem={(item, index) => (
            <List.Item>
              <List.Item.Meta
                title={item.name}
                description={item.createTime}
              />
              <div>
                <Button className={styles.nonendButton} onClick={()=>{
                  navigate(`/tianhua_consistency/${item.name}/${item.projectId}`);
                }}>进入项目</Button>
                <Button onClick={
                  ()=>{
                    ConsistencyListService.deleteConsistencyProject(item.projectId.toString())
                    .then(res => {
                      notification['success']({
                        message: '删除项目成功',
                        description:
                          `项目 “${item.name}” 已成功删除。`,
                      });
                    })
                    .catch(res => {
                      notification['error']({
                        message: '删除项目失败',
                        description:
                          `删除项目 “${item.name}” 失败，请检查网络。`,
                      });
                    });
                    const newData = [...data];
                    newData.splice(index, 1);
                    setData(newData);
                  }
                }>删除项目</Button>
              </div>
            </List.Item>
          )}
        />
      </Card>
    </div>
  );
}

export default TianhuaConsistencyList;