import { Form, Space, Select, TreeSelect, Button, Card } from 'antd'; // 请确保正确导入所需的组件
import React, { useState, useEffect, useMemo } from 'react';
import { FieldDataNode } from 'rc-tree/lib/interface';
import { SNLLibrary } from '../../../models/snl/SNLLibrary';
import { FormInstance } from 'antd/lib/form/Form';
import { RuleTreeDataNode } from '../../../utils/snlUtils';
import {
  selectSNLs
} from '../tuzhiVisSlice';
import { useAppSelector } from '../../../app/hooks';
import SNLService from './SNL.service';
import { CloseOutlined } from '@ant-design/icons';

interface OnlineSNLFormProps {
  form: FormInstance
}
interface SNLFormValue {
  snlFile?: File;
  selectedRules?: string[];
  selectedTags?: string[];
  selectedSnlTags?: string[];
  snlFileData?: string;
}

interface SNLComponentProps {
  id?: string;
  value?: SNLFormValue;
  onChange?: (value: SNLFormValue) => void;
}
function OnlineSNLForm(props: OnlineSNLFormProps) {
  const { form } = props;
  const checkOnlineSnl = (_: any, value: SNLFormValue) => {
    if (!value || !value.snlFile) {
      return Promise.reject(new Error('未选择待审查的SNL文件'));
    }

    const snlList = form.getFieldValue('snlList') || [];
    const snlFiles: File[] = snlList.map((s: any) => {
      return s?.componentData?.snlFile;
    });

    const isDuplicate = snlFiles.filter((file: File) => file.name === value.snlFile!.name).length > 1;
    if (isDuplicate) {
      return Promise.reject(new Error('选择的 SNL 文件与之前的文件重复'));
    }

    if (!value.selectedRules || value.selectedRules.length === 0) {
      return Promise.reject(new Error('未选择待审查的条款'));
    }
    return Promise.resolve();
  };
  const SNLComponet: React.FC<SNLComponentProps> = (props) => {
    const { id, value = {}, onChange } = props;
    const [onlineSnlFile, setOnlineSnlFile] = useState<File | null>(null); // SNL文件内容
    const [onlineSnlLibrary, setOnlineSnlLibrary] = useState<SNLLibrary | null>(null); // SNL库对象
    const [onlineRuleTree, setOnlineRuleTree] = useState<RuleTreeDataNode[]>([]); // SNL目录树
    const [onlineTagTreeData, setOnlineTagTreeData] = useState<FieldDataNode<any>[]>([]); // SNL目录树
    const [onlineSelectedRules, setOnlineSelectedRules] = useState<string[]>([]); // SNL选择的条文编号列表
    const [onlineSelectedTags, setOnlineSelectedTags] = useState<string[]>([]); // SNL选择的条文Tag列表
    const [onlineSelectedSnlTags, setOnlineSelectedSnlTags] = useState<string[]>([]); // SNL选择的snl Tag列表
    const [onlineSnlFileData, setOnlineSnlFileData] = useState<string>(''); // snl文件内容
    const [onlineSnlTagTreeData, setOnlineSnlTagTreeData] = useState<FieldDataNode<any>[]>([])
    const [initialAdd, setInitialAdd] = useState(true);
    const snls = useAppSelector(selectSNLs);

    useEffect(() => {
      if (!initialAdd && onChange) {
        onChange({
          selectedRules: onlineSelectedRules,
          selectedTags: onlineSelectedTags,
          selectedSnlTags: onlineSelectedSnlTags,
          snlFile: onlineSnlFile ?? undefined,
          snlFileData: onlineSnlFileData
        });
      }
      setInitialAdd(false);
    }, [onlineSelectedRules, onlineSelectedTags, onlineSelectedSnlTags, onlineSnlFile]);
    const onlineRuleTreeData = useMemo(() => {
      return onlineSnlLibrary ? [onlineSnlLibrary?.getAntdTree(onlineSelectedTags, onlineSelectedSnlTags)] : [];
    }, [onlineSnlLibrary, onlineSelectedTags, onlineSelectedSnlTags]);
    return (
      <Space.Compact direction="vertical" style={{ width: '100%' }}>
        <Select
          style={{ width: '100%' }}
          showSearch
          placeholder="在线SNL"
          optionFilterProp="children"
          onSelect={(v: string) => {
            setOnlineSelectedRules([]);
            setOnlineSelectedTags([]);
            setOnlineSelectedSnlTags([]);
            SNLService.getOnlineSNLContent(v)
              .then(res => {
                setOnlineSnlFile(new File([res.data], v)); // 构造File对象兼容本地上传内容
                setOnlineSnlFileData(res.data);
                const snlLibrary = SNLLibrary.loadFromXMLString(res.data);
                setOnlineSnlLibrary(snlLibrary);
                setOnlineSelectedTags(Object.values(snlLibrary.tags).map(t => t.id));
                setOnlineSnlTagTreeData(snlLibrary.getAntdSnlTagsTree())
                setOnlineTagTreeData(snlLibrary.getAntdTagsTree())
              });
          }}
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          options={snls.snls.map(name => ({
            label: name, value: name,
          }))}
        />
        <Space.Compact direction="horizontal" style={{ width: '100%' }}>
          <TreeSelect
            style={{
              width: '50%',
            }}
            treeData={onlineTagTreeData}
            value={onlineSelectedTags}
            onChange={(newValue) => {
              setOnlineSelectedTags(newValue);
              setOnlineSelectedSnlTags([]);
              setOnlineSelectedRules([]);
              console.log(newValue);
            }}
            treeCheckable={true}
            placeholder="选择筛选条文标签"
            treeLine
            autoClearSearchValue
            treeNodeFilterProp="name"
            treeDefaultExpandAll
            showCheckedStrategy={TreeSelect.SHOW_CHILD}
            maxTagCount={0}
            maxTagPlaceholder={(v) => <span>{`已选择${v.length}个标签`}</span>}
            listHeight={400}
          />
          <TreeSelect
            style={{
              width: '50%',
            }}
            treeData={onlineSnlTagTreeData}
            value={onlineSelectedSnlTags}
            onChange={(newValue) => {
              setOnlineSelectedSnlTags(newValue);
              setOnlineSelectedTags([]);
              setOnlineSelectedRules([]);
              console.log(newValue);
            }}
            treeCheckable={true}
            placeholder="选择筛选snl标签"
            treeLine
            autoClearSearchValue
            treeNodeFilterProp="name"
            treeDefaultExpandAll
            showCheckedStrategy={TreeSelect.SHOW_CHILD}
            maxTagCount={0}
            maxTagPlaceholder={(v) => <span>{`已选择${v.length}个标签`}</span>}
            listHeight={400}
          />
        </Space.Compact>
        <TreeSelect
          style={{
            width: '100%',
          }}
          treeData={onlineRuleTreeData}
          value={onlineSelectedRules}
          onChange={(newValue) => {
            setOnlineSelectedRules(newValue);
          }}
          treeCheckable={true}
          placeholder="选择审查规范编号"
          treeLine
          autoClearSearchValue
          treeNodeFilterProp="title"
          treeDefaultExpandAll
          showCheckedStrategy={TreeSelect.SHOW_CHILD}
          maxTagCount={0}
          maxTagPlaceholder={(v) => <span>{`已选择${v.length}条`}</span>}
          listHeight={400}
        />
      </Space.Compact>
    );
  }


  return (
    <Form
      form={form}
      name="onlineSnlComponents"
      onFinish={(values) => {
        console.log('Form values:', values);
      }}
      initialValues={{ snlList: [{}] }}
    >
      <Form.List name="snlList">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <div key={field.key}>
                <Card
                  key={field.key}
                  extra={
                    index > 0 && (
                      <CloseOutlined
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    )
                  }
                  style={{ width: '100%' }}
                  size="small"
                >
                  <Form.Item
                    {...field}
                    name={[field.name, 'componentData']}
                    key={field.key}
                    style={{ marginBottom: '0px' }}
                    rules={[{ validator: checkOnlineSnl }]}
                    validateTrigger={["onSubmit", "onChange"]}
                  >
                    <SNLComponet />
                  </Form.Item>
                </Card>
              </div>
            ))}
            <Button type="dashed" onClick={() => { add(); console.log(form.getFieldsValue()) }} block>
              + 添加规范
            </Button>
          </>
        )}
      </Form.List>
    </Form>
  );
}




export default React.memo(OnlineSNLForm);
