import React, { useContext, useEffect, useState } from 'react';
import styles from './CheckResultViewer.module.less';
import {CheckReport, TaskResultDiff, CheckResult} from '../../../interfaces';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  fetchSpaceRelations,
  selectElements,
  selectGeoms, selectSpaceRelations,
  selectSpaces, setActiveSpaceId,
  setSelectedElementIdsBySpaceId,
  setSpaceViewport,
} from '../tuzhiVisSlice';
import {Button, Modal, Popover, Table, Tag, Collapse, Segmented, message } from 'antd';
import { LayoutContext } from '../TuzhiVis';
import { bbox } from '@turf/turf';
import * as FlexLayout from 'flexlayout-react';
import { InView } from 'react-intersection-observer';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import ModelCheckerService from './ModelChecker.service';

function computeTransformMatrix(x1: number, y1: number, x2: number, y2: number, width: number, height: number) {
  const rectWidth = x2 - x1;
  const rectHeight = y2 - y1;
  const ratioX = width / rectWidth;
  const ratioY = height / rectHeight;
  const minRatio = Math.min(ratioX, ratioY);
  const scale = minRatio;
  const rectCenterX = (x1 + x2) / 2;
  const rectCenterY = (y1 + y2) / 2;
  const centerX =width / 2;
  const centerY = height / 2;
  const translateX = centerX - rectCenterX;
  const translateY = centerY - rectCenterY;
  const matrix = [
    [scale, 0, 0],
    [0, scale, 0],
    [translateX, translateY, 1]
  ];
  return matrix;
}

interface CheckReportViewerProps {
  bcResult: CheckReport | null;
  checkId: string;
  refreshReport: () => void;
  showTypeList: {
    [key: string]: boolean
  };
}

function CheckReportViewer(props: CheckReportViewerProps) {
  const {bcResult, checkId, refreshReport, showTypeList} = props;
  const { Panel } = Collapse;

  const dispatch = useAppDispatch();

  const layoutContext = useContext(LayoutContext);

  const spaces = useAppSelector(selectSpaces);
  const elements = useAppSelector(selectElements);
  const geoms = useAppSelector(selectGeoms);
  const spaceRelations = useAppSelector(selectSpaceRelations);
  const [checkResultIdMap, setCheckResultIdMap] = useState<Map<CheckResult, number>>(new Map());

  useEffect(() => {
    if (bcResult) {  
      const newCheckResultIdMap = new Map<CheckResult, number>();
  
      for (const itemKey in bcResult.ItemResults) {
        const checkResults = bcResult.ItemResults[itemKey].CheckResults;
        checkResults.forEach((check, index) => {
          newCheckResultIdMap.set(check, index);
        });
      }
  
      setCheckResultIdMap(newCheckResultIdMap);
    }
  }, [bcResult]);


  // todo 还要考虑构件是建筑、标高等抽象构件的情况
  const onPreview = (spaceId: number, elementId: number) => {
    // 新建tab页或跳转tab页，打开当前资源
    const tabId = `space-${spaceId}`;
    if (layoutContext.model.getNodeById(tabId)) {
      layoutContext.model.doAction(
        FlexLayout.Actions.selectTab(tabId),
      );
    } else {
      layoutContext.model.doAction(FlexLayout.Actions.addNode(
        {
          id: tabId, type: 'tab',
          component: 'union-viewer',
          name: `[空间${spaceId}]${spaces.find(s => s.spaceId === spaceId)?.name}`,
          config: {spaceId: spaceId},
        },
        'viewer-pane', FlexLayout.DockLocation.CENTER, -1, true,
      ));
    }
    // 更新当前激活的空间
    dispatch(setActiveSpaceId(spaceId));
    dispatch(setSelectedElementIdsBySpaceId({spaceId, elementIds: [elementId]}));
    // 打开页面后进行定位
    const g = elements?.[elementId.toString()]?.geoms?.map(gId => geoms[gId.toString()]).find(g => g.spaceId === spaceId);
    if (g) {
      const aabb = bbox(g.geom);
      const matrix = computeTransformMatrix(aabb[0], aabb[1], aabb[2], aabb[3], 1200, 900);
      console.log('定位后matrix', matrix);
      dispatch(setSpaceViewport({
        spaceId, viewport: [matrix[0][0], matrix[1][0], matrix[0][1], -matrix[1][1], matrix[2][0] * matrix[0][0], -matrix[2][1] * matrix[0][0]],
      }));
    }
};

  const handleAcceptChange = (acceptStatus: string, itemIndex: string, checkIndex: number) => {
    ModelCheckerService.updateReport(checkId,itemIndex,checkIndex, acceptStatus)
    .then(res => {
      console.log('Save successful');
      refreshReport()
    })
    .catch(res => {
      message.error('保存意见失败');
    });
  };

  
  
  if (!bcResult)
    return null;
  
  return (
    <div className={styles.resultWrapper}>
      {
        Object.keys(bcResult['ItemResults'])
          .filter(itemKey => {
            // @ts-ignore
            if (!showTypeList['通过'] && bcResult['ItemResults'][itemKey]['PassStatus'] === 'pass')
              return false;
            // 条文关联过滤逻辑
            if (showTypeList['只看被条文关联影响的条文'] && (bcResult['ItemResults'][itemKey]['affectedByRelateSNL'] === undefined || !bcResult['ItemResults'][itemKey]['affectedByRelateSNL']))
              return false;
            // 不适用逻辑
            return !bcResult.ItemResults[itemKey].CheckResults.every(c => c.ErrorType === '5不适用');
          })
          .sort((a, b) => {
            const itemA = bcResult['ItemResults'][a];
            const itemB = bcResult['ItemResults'][b];
            const itemANameMatch = itemA['Item'].match(/(.*)\.s[np]l(.*)/);
            const itemBNameMatch = itemB['Item'].match(/(.*)\.s[np]l(.*)/);
            if (itemANameMatch?.[1] && itemBNameMatch?.[1]) {
              return (itemANameMatch[1]).localeCompare(itemBNameMatch[1]);
            }
            if (itemANameMatch?.[2] && itemBNameMatch?.[2])
              return itemANameMatch[2].split('.').map( n => +n+100000 ).join('.') >
              itemBNameMatch[2].split('.').map( n => +n+100000 ).join('.') ? 1: -1;
            return -1;
          })
          .map((itemKey) => {
            const item = bcResult['ItemResults'][itemKey];
            const itemNameMatch = item['Item'].match(/(.*)\.s[np]l(.*)/);
            if (!itemNameMatch)
              return null;
            const itemName = itemNameMatch[2] + ' - ' + itemNameMatch[1];

            // 将每个 item 的 CheckResults 按照 SNL 分组
            const groupedFilterCheckResults = item['CheckResults']
              .filter(checkResult => showTypeList[checkResult['ErrorType']])
              .reduce((acc, checkResult) => {
                const snl = checkResult.SNL;
                acc[snl] = acc[snl] || [];
                acc[snl].push(checkResult);
                return acc;
              }, {} as { [key: string]: CheckResult[] });

            // 拼接错误列表
            const snlErrorList = Object.keys(groupedFilterCheckResults).filter(c => {
              // 如果不需要显示通过的 SNL，并且所有的检查结果都是通过的，则过滤掉
              if (!showTypeList['通过'] && groupedFilterCheckResults[c].every(cr => cr['Pass'])) {
                return false;
              }
              // 如果不需要显示未通过的 SNL，并且存在一个或多个检查结果未通过即SNL未通过，则过滤掉
              if (!showTypeList['未通过'] && groupedFilterCheckResults[c].some(cr => !cr['Pass'])) {
                return false;
              }
              // 如果不需要显示设计说明，且 SNL 包含 '文本'，则过滤掉
              if (!showTypeList['设计说明'] && c.includes('文本')) {
                return false;
              }
              // 如果检查结果都是 '5不适用'，则过滤掉
              return !groupedFilterCheckResults[c].every(cr => cr.ErrorType === '5不适用');
            }).map((snl, i) => {
              const errorList = groupedFilterCheckResults[snl].map((check, i) => {
                if (!showTypeList[check['ErrorType']])
                  return null;
                // 把错误值map解出来
                const valueMap = check.errValue.reduce((o, a) => {
                  const match = a.match(/\[(\S+)\s+(\S+)]/);
                  if (match) {
                    if (parseFloat(match[2])) {
                      // @ts-ignore
                      o[match[1]] = +parseFloat(match[2]).toFixed(2);
                    } else {
                      // @ts-ignore
                      o[match[1]] = match[2];
                    }
                  }
                  return o;
                }, {});

                const showCheckResultDiff = (dataSource: TaskResultDiff[]) => {
                  const columns = [
                    {
                      title: '过滤类型',
                      dataIndex: 'filterConfigRuleName',
                      key: 'filterConfigRuleName',
                    },
                    {
                      title: '规则 id',
                      dataIndex: 'filterConfigRuleId',
                      key: 'filterConfigRuleId',
                    },
                    {
                      title: '条文 A',
                      dataIndex: 'ruleAndArticleA',
                      key: 'ruleAndArticleA',
                    },
                    {
                      title: '过滤前报错 id',
                      dataIndex: 'oldIdSet',
                      key: 'oldIdSet',
                    },
                    {
                      title: '过滤后报错 id',
                      dataIndex: 'newIdSet',
                      key: 'newIdSet',
                    }
                  ];

                  Modal.info({
                    title: '条文关联过滤记录',
                    width: 1500,
                    content: (
                      <Table dataSource={dataSource} columns={columns}/>
                    )
                  });
                };
                  const checkId = checkResultIdMap.get(check);
                  return (
                    <tr key={i}>
                      <td className={styles.checkItem}>
                        <div><b className={styles.itemPass}>{check['Pass'] ? '通过' : '未通过'}</b><b>{check['Pass'] ? '未发现 ' : ''}{check['ErrorType']}</b>
                          &nbsp;&nbsp;&nbsp;
                          <b>{check['taskResultDiffs'] !== undefined && check['taskResultDiffs'].length !== 0 ? <button onClick={() => showCheckResultDiff(check['taskResultDiffs'])}>查看条文关联过滤记录</button> : ''}</b>
                          <Segmented
                            options={[
                              { label: '采纳', value: 'accepted' },
                              { label: '不采纳', value: 'rejected' },
                              { label: '搁置', value: 'pending' },
                            ]}
                            value={
                              check.acceptStatus !== undefined ? check.acceptStatus : 'pending'
                            }
                            onChange={(value) => handleAcceptChange(value as string, itemKey, checkId!)}
                          />
                        </div>
                        <div><b>检查内容：</b>{check['Reason']}</div>
                        <div><b>SNL原文：</b>{
                          <div
                            style={{color: '#005'}}
                            dangerouslySetInnerHTML={{__html: check['SNL'].replace(/(如果|并且|那么| 的 | 有 | 或 | 是 | 不 |contains|notcontains)/g, '<b style="color: #800">$1</b>').trim().replace(/\n+/g, '<br>')}} />
                        }</div>
                        {check['ifPass'] !== undefined ? (<b>SNL IF 审查结果: {check['ifPass'] ? <Tag color='#00FF00'>IF 通过</Tag> : <Tag color='#FF0000'>IF 不通过</Tag>}</b>) : '' }
                        {
                          check['IdSet'].length > 0 &&
                          <div><b>错误构件ID列表</b></div>
                        }
                        <div>
                          {/*todo 获取构件原始数据后可视化*/}
                          {check['IdSet'].map(id => {
                            // todo 处理构件pair情况
                            let idList = [id];
                            if (id.match(/\((\d+,\d+)\)/)) {
                              idList = id.match(/\d+/g) || [];
                            }

                            return (
                              <Popover key={id} trigger={['click']} content={<div>
                                <Button.Group>
                                  {
                                    idList.map(id => {
                                      return elements[id] && elements[id].geoms?.map(gId => {
                                        // todo
                                        const spaceId = geoms[gId.toString()]?.spaceId;
                                        const spaceName = spaces.find(s => s.spaceId === spaceId)?.name;

                                        // 查询当前spaceId的所有父Id，并全部列出
                                        console.log(spaceRelations);
                                        const parent = spaceRelations.filter(r => r.childId === spaceId && r.parentId !== spaceId);

                                        return (
                                          <div key={gId.toString()}>
                                            <Button
                                              onClick={() => {
                                                onPreview(spaceId, Number(id));
                                              }}
                                            >
                                              [{id}]: 空间[{spaceName}]
                                            </Button>
                                            {parent.map(p => {
                                              const parentSpaceName = spaces.find(s => s.spaceId === p.parentId)?.name;
                                              return (
                                                <Button
                                                  key={p.parentId}
                                                  onClick={() => {
                                                    onPreview(p.parentId, Number(id));
                                                  }}
                                                >
                                                  [{p.parentId}]: 空间[{parentSpaceName}]
                                                </Button>
                                              );
                                            })}
                                          </div>
                                        );
                                      })
                                    })
                                  }
                                </Button.Group>
                              </div>}>
                                <Tag
                                  key={id}
                                  className={styles.elementIdButton}
                                  color="red"
                                  onClick={() => {
                                    // alert(JSON.stringify(elements, null, 4));
                                  }}
                                >
                                  {
                                    idList.map(id => (
                                      <div key={id}>{id} {elements[id]?.elementName}</div>
                                    ))
                                  }
                                </Tag>
                              </Popover>
                            )
                          })}
                        </div>
                      </td>
                    </tr>
                  );
              }).filter(o => o !== null);
              if (errorList.length === 0)
                return null; 
              const errorTypeIncluded = ['3构件错误','4硬性错误']
              const snlHasError = groupedFilterCheckResults[snl].some(cr => !cr['Pass'])
              
              const checkReason = groupedFilterCheckResults[snl].map((check, i) => {
                if (errorTypeIncluded.includes(check.ErrorType)) {
                  return check.Reason;
                }
                return '';
              }).join("");
              return (
                <Panel
                  header={
                    <span>
                      {snlHasError ? <CloseCircleOutlined title="未通过" style={{ color: 'red' }} /> : <CheckCircleOutlined title="通过" style={{ color: 'green' }} />}
                      {snl}
                      {checkReason.length !== 0 && (
                        <div>
                          <b>检查内容：</b>
                          {checkReason}
                        </div>
                      )}
                    </span>
                  }
                  key={snl}
                >
                  
                  <table>
                    <tbody>
                      {errorList}
                    </tbody>
                  </table>
                </Panel>

              );
            }).filter(o => o !== null);

            if (snlErrorList.length === 0)
              return null;
            return <div id={`check-report-${itemName}`} key={itemKey} className={styles.ruleItem}>
              <div className={styles.ruleItemTitle}>
                <Tag color={item.PassStatus === 'pass' ? '#2d0': '#d20'}>{item['PassStatus']}</Tag>
                {itemName}&nbsp;&nbsp;
                {item.affectedByRelateSNL ? <Tag color='#0000FF'>条文关联</Tag>: ''}
              </div>
              <InView as="table" className={styles.resultTable} onChange={(inView, entry) => console.log(itemNameMatch[2], inView)}>
                <tbody>
                <tr><td className={styles.title}>规则原文</td></tr>
                <tr><td>{item['NaturalLanguage']}</td></tr>
                  {
                    snlErrorList.length > 0 &&
                    <>
                      <tr><td className={styles.title}>错误列表</td></tr>
                      <tr><td>
                        <Collapse>
                          {snlErrorList}
                        </Collapse>
                      </td></tr>
                    </>
                  }
                </tbody>
              </InView>
            </div>;
          })
          .filter(o => o !== null)
      }
    </div>
  );
}

export default React.memo(CheckReportViewer);

