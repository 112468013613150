import * as cheerio from 'cheerio';
import { RuleTreeDataNode } from '../../utils/snlUtils';
import { FieldDataNode } from 'rc-tree/lib/interface';

export class SNLSentence {
  id: string;
  rule: SNLRule;
  snlContent: string;
  splitText: string;
  tags: SNLTag[];

  constructor(id: string, rule: SNLRule, snlContent: string, splitText: string, tags: SNLTag[]) {
    this.id = id;
    this.rule = rule;
    this.snlContent = snlContent;
    this.splitText = splitText;
    this.tags = tags;
  }
}

export class SNLTag {
  id: string;
  name: string;

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }
}

export class SNLConfigItem {
  name: string;
  mapping: string;

  constructor(name: string, mapping: string) {
    this.name = name;
    this.mapping = mapping;
  }
}

export class SNLRule {
  id: string;
  category: SNLCategory;
  number: string;
  tags: SNLTag[];
  des: string;
  snls: SNLSentence[];

  constructor(id: string, category: SNLCategory, number: string, des: string, tags: SNLTag[] = [], snls: SNLSentence[] = []) {
    this.id = id;
    this.category = category;
    this.number = number;
    this.des = des;
    this.tags = tags;
    this.snls = snls;
  }
}

export class SNLCategory {
  id: string;
  number: string;
  name: string;
  children: SNLCategory[];
  rules: SNLRule[];

  constructor(id: string, number: string, name: string, children: SNLCategory[] = [], rules: SNLRule[] = []) {
    this.id = id;
    this.number = number;
    this.name = name;
    this.children = children;
    this.rules = rules;
  }
}

type TagId = string;
type RuleId = string;
type SNLSentenceId = string;
type ConfigName = string;

export class SNLLibrary {
  name: string;
  rootCategory: SNLCategory;
  tags: { [key: TagId]: SNLTag } = {};
  rules: { [key: RuleId]: SNLRule } = {};
  snls: { [key: SNLSentenceId]: SNLSentence } = {};
  configs: { [key: ConfigName]: SNLConfigItem } = {};

  constructor(
    name: string,
    rootCategory: SNLCategory,
    tags: { [key: TagId]: SNLTag },
    rules: { [key: RuleId]: SNLRule },
    snls: { [key: SNLSentenceId]: SNLSentence },
    configs: { [key: ConfigName]: SNLConfigItem },
  ) {
    this.name = name;
    this.rootCategory = rootCategory;
    this.tags = tags;
    this.rules = rules;
    this.snls = snls;
    this.configs = configs;
  }

  getAntdTagsTree(): FieldDataNode<any>[] {
    return Object.values(this.tags).map(tag => ({
      value: tag.id,
      title: tag.name,
      children: [],
    })).concat([{
      value: 'no-tag',
      title: '无标签',
      children: [],
    }]);
  }

  getAntdSnlTagsTree(): FieldDataNode<any>[] {
    const snlTags = new Set(Object.values(this.snls).flatMap(snl => snl.tags).filter(tag => tag));

    return Array.from(snlTags).map(tag => ({
      value: tag.id,
      title: tag.name,
      children: [],
    })).concat([{
      value: 'no-tag',
      title: '无标签',
      children: [],
    }]);
  }

  getAntdTree(filteredTagIds: TagId[] = [], filteredSnlTagIds: TagId[] = []): RuleTreeDataNode {
    function _extractAntdTree(node: SNLCategory | SNLRule): RuleTreeDataNode {
      // 目前不支持非叶子节点上挂载条文
      if (node instanceof SNLRule) {
        let disabled = false;
        if (filteredSnlTagIds.length !== 0){

          if (node.snls.every(snl => (snl.tags.length === 0 && !filteredSnlTagIds.includes('no-tag')) || (snl.tags.length !== 0 && !snl.tags.some(tag => filteredSnlTagIds.includes(tag.id))))) {
            disabled = true;
          }
        } else if (
          node.tags.length === 0 &&
          !filteredTagIds.includes('no-tag') ||
          node.tags.length !== 0 &&
          filteredTagIds.length !== 0 &&
          node.tags.filter(t => filteredTagIds.includes(t.id)).length === 0
        ) {
          disabled = true;
        }
        return {
          value: node.number,
          title: `${node.number} (${node.tags.map(t => t.name).join(',')})`,
          ruleNumber: node.number,
          children: [],
          disabled,
        };
      } else {
        return {
          value: node.number,
          title: node.number + ' ' + node.name,
          ruleNumber: node.number,
          children: (node.children.length !== 0 ? node.children : node.rules).map(_extractAntdTree),
        };
      }
    }

    return _extractAntdTree(this.rootCategory);
  }

  private static naturalSort(a: SNLCategory | SNLRule, b: SNLCategory | SNLRule)  {
    const aLevels = a.number.split('.').map(n => parseInt(n));
    const bLevels = b.number.split('.').map(n => parseInt(n));

    const maxLevel = Math.max(aLevels.length, bLevels.length);
    for (let i = 0; i < maxLevel; i++) {
      const aLevel = i < aLevels.length ? aLevels[i] : 0;
      const bLevel = i < bLevels.length ? bLevels[i] : 0;
      if (aLevel !== bLevel) {
        return aLevel - bLevel;
      }
    }
    return 0;
  }

  static loadFromXMLString(xmlStr: string): SNLLibrary {
    const $ = cheerio.load(xmlStr, {xmlMode: true});
    // 解析基本信息
    const name = $('lib_name').text();
    // 先构造目录节点
    const superRoot: SNLCategory = new SNLCategory('root', '-1', 'root', [], []);
    $('class').each((index, $category) => {
      const catIdList = $category.attribs['id'];
      const name = $($category).find('class_name').text();
      const number = $($category).find('class_number').text();
      // 这里默认了原始分类顺序是按照从根到叶子的
      catIdList.split(',').reduce((node, id) => {
        const childId = `${node.id}-${id}`;
        let child = node.children.find(n => n.id === childId);
        if (!child) {
          // 当number为空时，默认以childId作为fallback
          child = new SNLCategory(childId, number || childId, name);
          node.children.push(child);
        }
        return child;
      }, superRoot);
    });
    // 解析标签
    const tags: { [key: TagId]: SNLTag } = {};
    $('group').each((index, $tag) => {
      const tagId = $tag.attribs['id'];
      const tagName = $($tag).find('group_name').text();
      tags[tagId] = new SNLTag(tagId, tagName);
    });
    // 再构造条款节点（叶子）
    const rules: { [key: RuleId]: SNLRule } = {};
    $('rule').each((index, $rule) => {
      const ruleId = $rule.attribs['id'];
      const catIdList = $($rule).find('whichclass').text();
      const ruleNumber = $($rule).find('rule_number').text();
      const ruleTagsStr = $($rule).find('rule_group').text();
      const ruleDes = $($rule).find('rule_nat_descpt').text();
      if (catIdList && ruleNumber) {
        const parent = catIdList.split(',').reduce((node, id) => {
          const childId = `${node.id}-${id}`;
          if (!node.children)
            throw 'SNL文件损坏,条款无对应分类';
          const child = node.children.find(n => n.id === childId);
          if (!child)
            throw 'SNL文件损坏,条款无对应分类';
          return child;
        }, superRoot);
        if (parent.children) {
          const ruleTags = ruleTagsStr ? ruleTagsStr.split(' ').map(tagId => tags[tagId]) : [];
          const snlRule = new SNLRule(ruleId, parent, ruleNumber, ruleDes, ruleTags);
          rules[ruleId] = snlRule;
          parent.rules.push(snlRule);
        }
      }
    });
    // 对条文进行自然排序（按点号分隔的每一个节点按数值排序）
    (function sort(node) {
      // 先排序当前节点下所有子节点
      if (node.children.length > 0) {
        node.children = node.children.sort(SNLLibrary.naturalSort);
        console.log('排序后cc', node.children);
      } else if (node.rules) {
        node.rules = node.rules.sort(SNLLibrary.naturalSort);
      }
      // 再递归处理所有子节点
      for (const child of node.children) {
        sort(child);
      }
    })(superRoot);
    // 解析SNL语句内容
    const snls: {[key: SNLSentenceId]: SNLSentence} = {}
    $('fml_descpt').each((index, $snl) => {
      const snlId = $snl.attribs['id'];
      const ruleId = $($snl).find('ruleId').text();
      const snlContent = $($snl).find('descpt_snl').text();
      const splitText = $($snl).find('descpt_snl_split_text').text();
      const snlTagStr = $($snl).find('snl_group').text()
      const snlTags =  snlTagStr ? snlTagStr.split(' ').map(tagId => tags[tagId]) : [];

      const snlSentence = new SNLSentence(snlId, rules[ruleId], snlContent, splitText, snlTags);
      snls[snlId] = snlSentence;
      rules[ruleId].snls.push(snlSentence);
    });
    // todo 解析映射
    const configs: {[key: ConfigName]: SNLConfigItem} = {};
    const configText = $('config_set').attr('text') || '';
    configText
      .split('\n')
      .filter(s => s)
      .map(s => {
        let [name, mapping] = s.split('=', 2);
        if (name && mapping) {
          name = name.trim();
          mapping = mapping.trim();
          configs[name] = new SNLConfigItem(name, mapping);
        }
      });

    console.log(configs);

    return new SNLLibrary(name, superRoot.children[0], tags, rules, snls, configs);
  }
}