import React, { useState } from 'react';

import styles from './Toolbar.module.less';
import { Button, message, Tabs, Tag , Form, Modal, Select, Alert} from 'antd';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectMode, setMode, startNewShape } from '../union-viewer/modifier-layer/modifierSlice';
import {
  selectActiveSpaceId,
  selectSelectedElementsBySpaceId,
  setSelectedElementIdsBySpaceId,
  selectElements,
  selectProject,
} from '../tuzhiVisSlice';
import TuzhiVisService from '../TuzhiVis.service';

interface ToolbarProps {
  username: string;
  projectId: string;
  projectName: string;
}

function Toolbar(props: ToolbarProps) {
  const {username, projectId, projectName} = props;

  const dispatch = useAppDispatch();
  const mode = useAppSelector(selectMode);
  const activeSpaceId = useAppSelector(selectActiveSpaceId);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [addRelForm] = Form.useForm();
  const selectedElementIds = useAppSelector(state => selectSelectedElementsBySpaceId(state, activeSpaceId));
  const elements = useAppSelector(selectElements);
  const project = useAppSelector(selectProject);
  const handleOk = () => {
    addRelForm.validateFields().then((values) => {
      console.log(selectedElementIds, values.relType );
      if (selectedElementIds.length !== 2) {
        message.error(`请选择两个构件，当前已选 ${selectedElementIds.length} 个`);
        return;
      }
      //添加判断
      TuzhiVisService.addElementRelation(selectedElementIds[0], selectedElementIds[1], values.relType).then(res =>{
        message.success('成功添加构件关系');
        setIsAddModalOpen(false);
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          message.error(`添加构件关系失败: ${err.response.data.message}`);
        } else {
          message.error('添加构件关系失败');
        }
        console.error(err);
        setIsAddModalOpen(false);
      });

      
    }).catch(errorInfo => {
      console.error(errorInfo);
    });
  };
  const handleCancel = () => {
    setIsAddModalOpen(false);
  };
  
  const handleSwap = () => {
    if (selectedElementIds.length === 2) {
      const swappedIds = [selectedElementIds[1], selectedElementIds[0]];
      if (activeSpaceId) {
        dispatch(setSelectedElementIdsBySpaceId({ spaceId: activeSpaceId, elementIds: swappedIds }));
      }
    }
  };
  return (
    <div className={styles.toolPanel}>
      <Tabs
        defaultActiveKey="1"
        size="small"
        tabBarGutter={10}
        tabBarStyle={{
          marginLeft: 10,
        }}
        // onChange={onChange}
        items={[
          {
            label: `项目信息`,
            key: '1',
            children: (
              <>
                <Tag color="purple">当前用户 | {username}</Tag>
                <Tag color="purple">项目ID | {projectId}</Tag>
                <Tag color="purple">项目名称 | {projectName}</Tag>
                <Tag color="purple">项目状态 | {project?.state}</Tag>
              </>
            ),
          },
        ].concat(process.env.REACT_APP_THEME === 'szzj' ? [] : [
          {
            label: `数据修改`,
            key: '2',
            children: (
              <>
                <Button.Group>
                  <Button
                    size="small"
                    onClick={() => {
                      // 判断当前激活的面板是否是一个合法空间
                      if (activeSpaceId) {
                        dispatch(startNewShape(activeSpaceId));
                      } else {
                        message.warn('请先选择一个空间面板');
                      }
                    }}
                  >
                    新增包围盒
                  </Button>
                  <Button
                    size="small"
                    onClick={() => {
                      message.info('右键待修改的包围盒并点击修改即可');
                    }}
                  >
                    修改包围盒
                  </Button>
                </Button.Group>
              </>
            ),
          },
          {
            label: `布局调整`,
            key: '3',
            children: (
              <>
                <Button.Group>
                  <Button size="small">
                    默认布局1
                  </Button>
                </Button.Group>
              </>
            ),
          },
          {
            label: `添加关系`,
            key: '4',
            children: (
              <>
                <Button.Group>
                  <Button
                    size="small"
                    onClick={e => {
                      e.stopPropagation();
                      setIsAddModalOpen(true);
                    }}
                  >
                    添加构件属性
                  </Button>
                </Button.Group>
              </>
            ),
          },
        ])}
      />
      <Modal title="增改属性" open={isAddModalOpen} onOk={handleOk} onCancel={handleCancel} maskClosable={false}>
        <Form form={addRelForm} layout="vertical">
          <Form.Item
            name="relType"
            label="构件关系"
            rules={[{ required: true, message: '请选择构件关系' }]}
          >

            <Select
              placeholder="构件关系"
              optionFilterProp="children"
              options={[
                {
                  value: 'CONTAINING',
                  label: 'CONTAINING',
                },
                {
                  value: 'HAS_BOUNDARY_ELEMENT',
                  label: 'HAS_BOUNDARY_ELEMENT',
                },
                {
                  value: 'HAS_SUBTYPE',
                  label: 'HAS_SUBTYPE',
                },
                {
                  value: 'IS_CONNECTED_TO',
                  label: 'IS_CONNECTED_TO',
                },
                {
                  value: 'INSERTED_ELEMENTS',
                  label: 'INSERTED_ELEMENTS',
                },
              ]}
            />
          </Form.Item>
        </Form>
        <div>
          <h3>选中的元素</h3>
          {selectedElementIds.length === 2 ? (
            <div>
              <div>元素 1: {elements[selectedElementIds[0]]?.elementName} {selectedElementIds[0]}</div>
              <div>元素 2: {elements[selectedElementIds[1]]?.elementName} {selectedElementIds[1]}</div>
              <Button onClick={handleSwap}>交换元素</Button>
              {!['面积', '房间', '空间'].includes(elements[selectedElementIds[0]]?.familyName) &&
                <div><Alert message="即将为构件添加关系" type="warning" /></div>
              }
            </div>
          ) : (
            <div>请选择两个构件,当前已选 {selectedElementIds.length} 个</div>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default Toolbar;
